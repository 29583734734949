import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify"
import theme from '../theme.jsx';
//import { useAlert 

import {
	FormControl,
	InputLabel,
	Paper,
	OutlinedInput,
	Checkbox,
	Card,
	Tooltip,
	FormControlLabel,
	Chip,
	Link,
	Typography,
	Switch,
	Select,
	MenuItem,
	Divider,
	ListItemText,
	TextField,
	Button,
	Tabs,
	Tab,
	Grid,
	Autocomplete,
} from "@mui/material";

import {
	Icon as IconButton,
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	Save as SaveIcon,
} from "@mui/icons-material";

const useStyles = makeStyles({
	notchedOutline: {
		borderColor: "#f85a3e !important",
	},
});

const OrgHeaderexpandedNew = (props) => {
	const {
		userdata,
		selectedOrganization,
		setSelectedOrganization,
		globalUrl,
		isCloud,
		adminTab,
		selectedStatus,
		setSelectedStatus,
		isEditOrgTab
	} = props;

	const classes = useStyles();
	const defaultBranch = "main";
	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 300,
				borderRadius: 20,
				overflowY: "scroll",
			},
		},
		getContentAnchorEl: () => null,
	};

	const [orgName, setOrgName] = React.useState(selectedOrganization.name);
	const [orgDescription, setOrgDescription] = React.useState(
		selectedOrganization.description
	);

	const [appDownloadUrl, setAppDownloadUrl] = React.useState(
		selectedOrganization.defaults === undefined
			? "https://github.com/frikky/shuffle-apps"
			: selectedOrganization.defaults.app_download_repo === undefined ||
				selectedOrganization.defaults.app_download_repo.length === 0
				? "https://github.com/frikky/shuffle-apps"
				: selectedOrganization.defaults.app_download_repo
	);

	const handleStatusChange = (event) => {
		const { value } = event.target;


		handleEditOrg(
			"",
			"",
			selectedOrganization.id,
			"",
			{},
			{},
			value.length === 0 ? ["none"] : value,
		)
	}
	const [appDownloadBranch, setAppDownloadBranch] = React.useState(
		selectedOrganization.defaults === undefined
			? defaultBranch
			: selectedOrganization.defaults.app_download_branch === undefined ||
				selectedOrganization.defaults.app_download_branch.length === 0
				? defaultBranch
				: selectedOrganization.defaults.app_download_branch
	);
	const [workflowDownloadUrl, setWorkflowDownloadUrl] = React.useState(
		selectedOrganization.defaults === undefined
			? "https://github.com/frikky/shuffle-apps"
			: selectedOrganization.defaults.workflow_download_repo === undefined ||
				selectedOrganization.defaults.workflow_download_repo.length === 0
				? "https://github.com/frikky/shuffle-workflows"
				: selectedOrganization.defaults.workflow_download_repo
	);
	const [workflowDownloadBranch, setWorkflowDownloadBranch] = React.useState(
		selectedOrganization.defaults === undefined
			? defaultBranch
			: selectedOrganization.defaults.workflow_download_branch === undefined ||
				selectedOrganization.defaults.workflow_download_branch.length === 0
				? defaultBranch
				: selectedOrganization.defaults.workflow_download_branch
	);
	const [ssoEntrypoint, setSsoEntrypoint] = React.useState(
		selectedOrganization.sso_config === undefined
			? ""
			: selectedOrganization.sso_config.sso_entrypoint === undefined ||
				selectedOrganization.sso_config.sso_entrypoint.length === 0
				? ""
				: selectedOrganization.sso_config.sso_entrypoint
	);
	const [ssoCertificate, setSsoCertificate] = React.useState(
		selectedOrganization.sso_config === undefined
			? ""
			: selectedOrganization.sso_config.sso_certificate === undefined ||
				selectedOrganization.sso_config.sso_certificate.length === 0
				? ""
				: selectedOrganization.sso_config.sso_certificate
	);
	const [notificationWorkflow, setNotificationWorkflow] = React.useState(
		selectedOrganization.defaults === undefined
			? ""
			: selectedOrganization.defaults.notification_workflow === undefined ||
				selectedOrganization.defaults.notification_workflow.length === 0
				? ""
				: selectedOrganization.defaults.notification_workflow
	);

	const [documentationReference, setDocumentationReference] = React.useState(
		selectedOrganization.defaults === undefined
			? ""
			: selectedOrganization.defaults.documentation_reference === undefined ||
				selectedOrganization.defaults.documentation_reference.length === 0
				? ""
				: selectedOrganization.defaults.documentation_reference
	);
	const [openidClientId, setOpenidClientId] = React.useState(
		selectedOrganization.sso_config === undefined
			? ""
			: selectedOrganization.sso_config.client_id === undefined ||
				selectedOrganization.sso_config.client_id.length === 0
				? ""
				: selectedOrganization.sso_config.client_id
	);
	const [openidClientSecret, setOpenidClientSecret] = React.useState(
		selectedOrganization.sso_config === undefined
			? ""
			: selectedOrganization.sso_config.client_secret === undefined ||
				selectedOrganization.sso_config.client_secret.length === 0
				? ""
				: selectedOrganization.sso_config.client_secret
	);
	const [openidAuthorization, setOpenidAuthorization] = React.useState(
		selectedOrganization.sso_config === undefined
			? ""
			: selectedOrganization.sso_config.openid_authorization === undefined ||
				selectedOrganization.sso_config.openid_authorization.length === 0
				? ""
				: selectedOrganization.sso_config.openid_authorization
	);
	const [openidToken, setOpenidToken] = React.useState(
		selectedOrganization.sso_config === undefined
			? ""
			: selectedOrganization.sso_config.openid_token === undefined ||
				selectedOrganization.sso_config.openid_token.length === 0
				? ""
				: selectedOrganization.sso_config.openid_token
	)
	const [newsletter, setNewsletter] = React.useState(
		selectedOrganization.defaults === undefined
			? true
			: selectedOrganization.defaults.newsletter === undefined ||
				selectedOrganization.defaults.newsletter.length === 0
				? true
				: !selectedOrganization.defaults.newsletter
	)

	const [weeklyRecommendations, setWeeklyRecommendations] = React.useState(
		selectedOrganization.defaults === undefined
			? true
			: selectedOrganization.defaults.weekly_recommendations === undefined ||
				selectedOrganization.defaults.weekly_recommendations.length === 0
				? true
				: !selectedOrganization.defaults.weekly_recommendations
	)

	const [uploadRepo, setUploadRepo] = React.useState(selectedOrganization.defaults === undefined ? "" : selectedOrganization.defaults.workflow_upload_repo === undefined || selectedOrganization.defaults.workflow_upload_repo.length === 0 ? "" : selectedOrganization.defaults.workflow_upload_repo)
	const [uploadBranch, setUploadBranch] = React.useState(selectedOrganization.defaults === undefined ? defaultBranch : selectedOrganization.defaults.workflow_upload_branch === undefined || selectedOrganization.defaults.workflow_upload_branch.length === 0 ? defaultBranch : selectedOrganization.defaults.workflow_upload_branch)
	const [uploadUsername, setUploadUsername] = React.useState(selectedOrganization.defaults === undefined ? "" : selectedOrganization.defaults.workflow_upload_username === undefined || selectedOrganization.defaults.workflow_upload_username.length === 0 ? "" : selectedOrganization.defaults.workflow_upload_username)
	const [uploadToken, setUploadToken] = React.useState(selectedOrganization.defaults === undefined ? "" : selectedOrganization.defaults.workflow_upload_token === undefined || selectedOrganization.defaults.workflow_upload_token.length === 0 ? "" : selectedOrganization.defaults.workflow_upload_token)
	const [regionStatus, setRegionStatus] = useState();

	const [workflows, setWorkflows] = React.useState([])
	const [workflow, setWorkflow] = React.useState({})

	const getAvailableWorkflows = () => {
		fetch(globalUrl + "/api/v1/workflows", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (response.status !== 200) {
					console.log("Status not 200 for workflows :O!");
					return;
				}
				return response.json();
			})
			.then((responseJson) => {
				if (responseJson !== undefined) {

					// Add parent notification workflow	if it's a child org
					// selectedOrganization,
					if (selectedOrganization.creator_org !== undefined && selectedOrganization.creator_org !== null && selectedOrganization.creator_org.length > 0) {
						// Add to start of the list
						responseJson.unshift({
							"name": "Parent-Org's Notification Workflow",
							"id": "parent",
						})
					}

					setWorkflows(responseJson)

					if (selectedOrganization.defaults !== undefined && selectedOrganization.defaults.notification_workflow !== undefined) {

						const workflow = responseJson.find((workflow) => workflow.id === selectedOrganization.defaults.notification_workflow)
						if (workflow !== undefined && workflow !== null) {
							setWorkflow(workflow)
						}
					}
				}
			})
			.catch((error) => {
				console.log("Error getting workflows: " + error);
			})
	}

	useEffect(() => {
		console.log("Should get workflows for notification workflow")
		getAvailableWorkflows()
	}, [])

	const handleEditOrg = (
		name,
		description,
		orgId,
		image,
		defaults,
		sso_config
	) => {

		const data = {
			name: name,
			description: description,
			org_id: orgId,
			image: image,
			defaults: defaults,
			sso_config: sso_config,
		};

		const url = globalUrl + `/api/v1/orgs/${selectedOrganization.id}`;
		fetch(url, {
			mode: "cors",
			method: "POST",
			body: JSON.stringify(data),
			credentials: "include",
			crossDomain: true,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json; charset=utf-8",
			},
		})
			.then((response) =>
				response.json().then((responseJson) => {
					if (responseJson["success"] === false) {
						toast("Failed updating org: ", responseJson.reason);
					} else {
						toast("Successfully edited org!");
					}
				})
			)
			.catch((error) => {
				toast("Err: " + error.toString());
			});
	}

	const handleWorkflowSelectionUpdate = (e, isUserinput) => {
		if (e.target.value === undefined || e.target.value === null || e.target.value.id === undefined) {
			console.log("Returning as there's no id")
			return null
		}

		setWorkflow(e.target.value)
		setNotificationWorkflow(e.target.value.id)
		toast("Updated notification workflow. Don't forget to save!")
	}
	const setSelectedRegion = (region) => {
		// send a POST request to /api/v1/orgs/{org_id}/region with the region as the body
		var data = {
			dst_region: region
		}

		toast("Changing region to " + region + "...This may take a few minutes.")

		fetch(`${globalUrl}/api/v1/orgs/${selectedOrganization.id}/change/region`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
			body: JSON.stringify(data),
			timeOut: 1000
		}).then((response) => {
			if (response.status !== 200) {
				toast("Failed to change region!")
			}
			else {
				toast("Region changed successfully! Reloading in 5 seconds..")
				// Reload the page in 2 seconds
				setTimeout(() => {
					window.location.reload()
				}, 5000)

			}

			return response.json();
		})
	}

	const RegionChangeModal = () => {
		// Show from options: "us-west2", "europe-west2", "europe-west3", "northamerica-northeast1"
		var regions = ["us-west2", "europe-west2", "europe-west3", "northamerica-northeast1"]
		return (
			<FormControl style={{ display: "flex", flexDirection: "column", marginTop: 5, alignItems: "center" }} >
				{/* <InputLabel id="demo-simple-select-label">Region</InputLabel> */}
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={selectedOrganization.region}
					style={{ width: 150, height: 51, borderRadius: 8 }}
					onChange={(e) => {
						setSelectedRegion(e.target.value);
					}}
				>
					{regions.map((region) => {
						// Set the default region if selectedOrganization.region is not set
						if (selectedOrganization.region === undefined) {
							selectedOrganization.region = "europe-west2";
						}

						// Check if the current region matches the selected region
						if (region === selectedOrganization.region) {
							// If the region matches, set the MenuItem as selected
							return (
								<MenuItem value={region} disabled>
									{region}
								</MenuItem>
							);
						} else {
							// Otherwise, render a regular MenuItem
							return <MenuItem value={region}>{region}</MenuItem>;
						}
					})}
				</Select>
			</FormControl>
		)
	}

	const orgSaveButton = (
		<Tooltip title="Save any unsaved data" placement="bottom">
			<Button
				style={{ width: 244, height: 51, flex: 1, textTransform: 'capitalize', padding: "16px, 24px, 16px, 24px", borderRadius: 300, backgroundImage: "linear-gradient(90deg, #F86744 0%, #F34475 100%)", fontSize: 16, }}
				variant="contained"
				color="primary"
				disabled={
					userdata === undefined ||
					userdata === null ||
					userdata.admin !== "true"
				}
				onClick={() =>
					handleEditOrg(
						orgName,
						orgDescription,
						selectedOrganization.id,
						selectedOrganization.image,
						{
							app_download_repo: appDownloadUrl,
							app_download_branch: appDownloadBranch,
							workflow_download_repo: workflowDownloadUrl,
							workflow_download_branch: workflowDownloadBranch,
							notification_workflow: notificationWorkflow,
							documentation_reference: documentationReference,
							workflow_upload_repo: uploadRepo,
							workflow_upload_branch: uploadBranch,
							workflow_upload_username: uploadUsername,
							workflow_upload_token: uploadToken,

							newsletter: !newsletter,
							weekly_recommendations: !weeklyRecommendations,
						},
						{
							sso_entrypoint: ssoEntrypoint,
							sso_certificate: ssoCertificate,
							client_id: openidClientId,
							client_secret: openidClientSecret,
							openid_authorization: openidAuthorization,
							openid_token: openidToken,
						}
					)
				}
			>
				Save Changes
				{/* <SaveIcon /> */}
			</Button>
		</Tooltip>
	);


	return (
		<div style={{ textAlign: "center" }}>

			{/*
			<Grid container spacing={3} style={{ textAlign: "left" }}>
				<Grid item xs={12} style={{}}>
					<Typography variant="body1">
						Email settings	
					</Typography>
					<Typography variant="body2" color="textSecondary" style={{marginBottom: 5, }}>	
						Enable or disable email notifications for your organization.
					</Typography>

					<div style={{display: "flex", }}>
					  <ListItemText primary={"Newsletter: "} style={{maxWidth: 90, marginTop: 10, }}/>
					  <Checkbox checked={newsletter} style={{marginTop: 3, }} 
						onChange={(e) => {
							setNewsletter(e.target.checked)
						}}
						disabled={!isCloud}
					  />

					  <ListItemText primary={"Weekly Recommendations: "} style={{marginLeft: 50, marginTop: 10, maxWidth: 225, }}/>
					  <Checkbox checked={weeklyRecommendations} style={{marginTop: 3, }}
						onChange={(e) => {
							setWeeklyRecommendations(e.target.checked)
						}}
						disabled={!isCloud}
					  />
					</div>
				</Grid> 
			</Grid> 
			*/}

			<Grid container spacing={3} style={{ textAlign: "left", marginTop: 5, }}>
				<Grid item xs={12} style={{}}>
					<span>
						<div style={{}}>
							<div style={{ flex: "3", color: "white" }}>
								<div style={{ marginTop: 8, display: "flex" }} />
								<div style={{ display: "flex" }}>
									<div>
										Name
										<TextField
											required
											style={{
												flex: "1",
												display: "flex",
												height: 51,
												width: 545,
												marginTop: "5px",
												marginRight: "15px",
												backgroundColor: isEditOrgTab ? "#212121" : theme.palette.inputColor,
											}}
											fullWidth={true}
											placeholder="Name"
											type="name"
											id="standard-required"
											margin="normal"
											variant="outlined"
											value={selectedOrganization.name}
											onChange={(e) => {
												const invalid = ["#", ":", "."];
												for (var key in invalid) {
													if (e.target.value.includes(invalid[key])) {
														toast("Can't use " + invalid[key] + " in name");
														return;
													}
												}

												if (e.target.value.length > 100) {
													toast("Choose a shorter name.");
													return;
												}

												setOrgName(e.target.value);
											}}
											color="primary"
											InputProps={{
												style: {
													color: "white",
													height: "50px",
													fontSize: "1em",
													borderRadius: 8
												},
												classes: {
													notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
												},
											}}
										/>
									</div>
									<div style={{ alignItems: 'center' }}>
										<div style={{ marginRight: '12px', color: 'white' }}>Status</div>
										<FormControl style={{ width: 220 }}>
											<Select
												style={{ minWidth: 220, marginTop: 5, maxWidth: 220, height: 51, borderRadius: 8 }}
												id="multiselect-status"
												multiple
												value={selectedStatus}
												onChange={handleStatusChange}
												input={<OutlinedInput />}
												renderValue={(selected) => selected.join(', ')}
												MenuProps={MenuProps}
											>
												{["contacted", "lead", "demo done", "pov", "customer", "open source", "student", "internal", "creator", "tech partner", "old customer", "old lead"].map((name) => (
													<MenuItem key={name} value={name}>
														<Checkbox checked={selectedStatus.indexOf(name) > -1} />
														<ListItemText primary={name} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>


									<div style={{ marginLeft: 13, fontSize: 16, color: "#9E9E9E" }} >
										Region
										<RegionChangeModal />
									</div>
								</div>
								<div style={{ marginTop: "10px" }} />
								About
								<div style={{ display: "flex" }}>
									<TextField
										required
										multiline
										rows={3}
										style={{
											flex: "1",
											marginTop: "5px",
											marginRight: "15px",
											backgroundColor: isEditOrgTab ? "#212121" : theme.palette.inputColor,
										}}
										fullWidth={true}
										type="name"
										id="outlined-with-placeholder"
										margin="normal"
										variant="outlined"
										placeholder="A description for the organization"
										value={selectedOrganization.description}
										onChange={(e) => {
											setOrgDescription(e.target.value);
										}}
										InputProps={{
											classes: {
												notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
											},
											style: {
												color: "white",
												height: 89,
												borderRadius: 8
											},
										}}
									/>
								</div>
								<div>

								</div>
							</div>
							<Typography variant="h5" style={{ color: "rgba(241, 241, 241, 1)", fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontSize: 24, fontWeight: 600, marginTop: 40, textAlign: "left" }}>
								Preferences
							</Typography>
							<Typography variant="body1" style={{ marginTop: 24, padding: 0, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>
								Notification Workflow
							</Typography>
							<Typography style={{ color: "rgba(158, 158, 158, 1)", fontSize: 16, fontWeight: 400, marginTop: 5, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", marginTop: 8 }}>
								Notification workflows are triggered when an error occurs in one of your workflows. Each individual one will only start a workflow once every 2 minutes.
							</Typography>

							{/*isCloud ? 
								<Chip
									label="Disabled on shuffler.io for now. Contact us for more info"
									color="secondary"
									style={{ marginLeft: 30, height: 30, }}
								/>
							: null*/}
						</div>
						{/*
						<Typography variant="body2" color="textSecondary">
							Add a Workflow that receives notifications from Shuffle when an error occurs in one of your workflows
						</Typography>
						*/}
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 16 }}>
							{workflows !== undefined && workflows !== null && workflows.length > 0 ?
								<Autocomplete
									id="notification_workflow_search"
									autoHighlight
									freeSolo
									//autoSelect
									value={workflow}
									classes={{ inputRoot: classes.inputRoot }}
									ListboxProps={{
										style: {
											backgroundColor: isEditOrgTab ? "#212121" : theme.palette.inputColor,
											color: "white",
										},
									}}
									getOptionLabel={(option) => {
										if (
											option === undefined ||
											option === null ||
											option.name === undefined ||
											option.name === null
										) {
											return "No Workflow Selected";
										}

										const newname = (
											option.name.charAt(0).toUpperCase() + option.name.substring(1)
										).replaceAll("_", " ");
										return newname;
									}}
									options={workflows}
									fullWidth
									style={{
										backgroundColor: isEditOrgTab ? "#212121" : theme.palette.inputColor,
										height: 50,
										borderRadius: theme.palette?.borderRadius,
									}}
									onChange={(event, newValue) => {
										console.log("Found value: ", newValue)

										var parsedinput = { target: { value: newValue } }

										// For variables
										if (typeof newValue === 'string' && newValue.startsWith("$")) {
											parsedinput = {
												target: {
													value: {
														"name": newValue,
														"id": newValue,
														"actions": [],
														"triggers": [],
													}
												}
											}
										}

										handleWorkflowSelectionUpdate(parsedinput)
									}}
									renderOption={(props, data, state) => {
										if (data.id === workflow.id) {
											data = workflow;
										}

										return (
											<Tooltip arrow placement="left" title={
												<span style={{}}>
													{data.image !== undefined && data.image !== null && data.image.length > 0 ?
														<img src={data.image} alt={data.name} style={{ backgroundColor: theme.palette.surfaceColor, maxHeight: 200, minHeigth: 200, borderRadius: theme.palette?.borderRadius, }} />
														: null}
													<Typography>
														Choose {data.name}
													</Typography>
												</span>
											} placement="bottom">
												<MenuItem
													style={{
														backgroundColor: theme.palette.inputColor,
														color: data.id === workflow.id ? "red" : "white",
													}}
													value={data}
													onClick={(e) => {
														var parsedinput = { target: { value: data } }
														handleWorkflowSelectionUpdate(parsedinput)
													}}
												>
													{data.name}
												</MenuItem>
											</Tooltip>
										)
									}}
									renderInput={(params) => {
										return (
											<TextField
												style={{
													backgroundColor: theme.palette.inputColor,
													borderRadius: theme.palette?.borderRadius,
												}}
												{...params}
												// label="Find a notification workflow"
												variant="outlined"
											/>
										);
									}}
								/>
								:
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "5px",
										marginRight: "15px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									placeholder="ID of the workflow to receive notifications"
									value={notificationWorkflow}
									onChange={(e) => {
										setNotificationWorkflow(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
										},
									}}
								/>
							}
							{/* <div style={{ minWidth: 150, maxWidth: 150, marginTop: 5, marginLeft: 10, }}>
								{orgSaveButton}
							</div> */}
						</div>
					</span>
				</Grid>
				<Grid item xs={12} style={{ marginTop: 24 }}>
					<span>
						<Typography style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Org Documentation reference</Typography>

						<Typography variant="body2" color="textSecondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16, marginTop: 8 }}>
							Add a URL that is added as a link, pointing to any external documentation page you want.
						</Typography>

						<TextField
							required
							style={{
								flex: "1",
								marginTop: "16px",
								marginRight: "15px",
								backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
							}}
							fullWidth={true}
							type="name"
							id="outlined-with-placeholder"
							margin="normal"
							variant="outlined"
							placeholder="Paste a URL to an external reference for this implementation"
							value={documentationReference}
							onChange={(e) => {
								setDocumentationReference(e.target.value);
							}}
							InputProps={{
								classes: {
									notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
								},
								style: {
									color: "white",
									fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
									fontWeight: 400,
									fontSize: 16,
									borderRadius: 8
								},
							}}
						/>
					</span>
				</Grid>

				<Grid item xs={12} style={{ marginTop: 20, }}>
					<Typography variant="h4" style={{ textAlign: "left", color: "rgba(241, 241, 241, 1)", fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontSize: 24, fontWeight: 600, }}>Workflow Backup Repository</Typography>
					<Typography variant="body2" style={{ textAlign: "left", marginTop: 8, color: "#9E9E9E", fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontSize: 16, fontWeight: 400 }}>
						Decide where workflows are backed up in a Git repository. Will create logs and notifications if upload fails. The repository and branch must already have been initialized. Files will show up in the repo root in the /orgId/workflow-status/workflowId.json format. <b>MSSP:</b> If suborg exists, this will automatically be applied for them as well (not retroactive). <a href="/docs/configuration#environment-variables" style={{textDecoration: "none", color: "#f86a3e"}} target="_blank">Credentials are encrypted.</a>
					</Typography>
					<Grid container style={{ marginTop: 10, }} spacing={2}>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Repository for workflow backup</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "8px",
										marginRight: "16px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									multiline={true}
									rows={1}
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									placeholder="Ex: github/com/shuffle/workflowbackup "
									value={uploadRepo}
									onChange={(e) => {
										setUploadRepo(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8
										},
									}}
								/>
							</span>
						</Grid>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Branch</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "8px",
										marginRight: "16px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									multiline={true}
									rows={1}
									placeholder="The branch to use for backup of workflows"
									value={uploadBranch}
									onChange={(e) => {
										setUploadBranch(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8
										},
									}}
								/>
							</span>
						</Grid>
					</Grid>
					<Grid container style={{ marginTop: 10, }} spacing={2}>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Username for backup of workflows</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "8px",
										marginRight: "16px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									multiline={true}
									rows={1}
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									placeholder="Enter Username"
									value={uploadUsername}
									onChange={(e) => {
										setUploadUsername(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8
										},
									}}
								/>
							</span>
						</Grid>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Git token/password</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "8px",
										marginRight: "16px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									multiline={true}
									rows={1}
									placeholder="The token to use for backup of workflows."
									value={uploadToken}
									onChange={(e) => {
										setUploadToken(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8
										},
									}}
									type="password"
								/>
							</span>
						</Grid>
					</Grid>
				</Grid>
				{/*isCloud ? null : */}
				<Grid item xs={12} style={{ marginTop: 20 }}>
					<span style={{ display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" style={{ textAlign: "left", color: "rgba(241, 241, 241, 1)", fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontSize: 24, fontWeight: 600, }}>OpenID connect</Typography>
						<span style={{ marginTop: 8, color: "rgba(158, 158, 158, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400 }}>
							Configure and Authorize SAML / SSO or OpenID connect. {" "}
							<a
								target="_blank"
								href="/docs/extensions#single-signon"
								style={{ color: "rgba(255, 132, 68, 1)" }}
							>
								Learn more
							</a>
						</span>
					</span>
					<Typography variant="body2" style={{ textAlign: "left", marginTop: 8, color: "rgba(158, 158, 158, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400 }}>
						IdP URL for Shuffle OpenID: <Link to="/api/v1/login_openid" style={{ color: "rgba(255, 132, 68, 1)", textDecoration: "none" }}>{`${globalUrl}/api/v1/login_openid`}</Link>
					</Typography>
					<Grid container style={{ marginTop: 8, }} spacing={2}>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ color: "rgba(255, 255, 255, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Client ID</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "5px",
										marginRight: "15px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									multiline={true}
									rows={2}
									disabled={
										selectedOrganization.manager_orgs !== undefined &&
										selectedOrganization.manager_orgs !== null &&
										selectedOrganization.manager_orgs.length > 0
									}
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									placeholder="The OpenID client ID from the identity provider"
									value={openidClientId}
									onChange={(e) => {
										setOpenidClientId(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8,
										},
									}}
								/>
							</span>
						</Grid>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ color: "rgba(255, 255, 255, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Client Secret</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "5px",
										marginRight: "15px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									multiline={true}
									rows={2}
									disabled={
										selectedOrganization.manager_orgs !== undefined &&
										selectedOrganization.manager_orgs !== null &&
										selectedOrganization.manager_orgs.length > 0
									}
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									placeholder="The OpenID client secret - DONT use this if dealing with implicit auth / PKCE"
									value={openidClientSecret}
									onChange={(e) => {
										setOpenidClientSecret(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8,
										},
									}}
								/>
							</span>
						</Grid>
					</Grid>
					<Grid container style={{ marginTop: 10, }} spacing={2}>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ color: "rgba(255, 255, 255, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Authorization URL</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "5px",
										marginRight: "15px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									multiline={true}
									rows={2}
									placeholder="The OpenID authorization URL (usually ends with /authorize)"
									value={openidAuthorization}
									onChange={(e) => {
										setOpenidAuthorization(e.target.value)
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8,
										},
									}}
								/>
							</span>
						</Grid>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ color: "rgba(255, 255, 255, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>Token URL</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "5px",
										marginRight: "15px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									multiline={true}
									rows={2}
									placeholder="The OpenID token URL (usually ends with /token)"
									value={openidToken}
									onChange={(e) => {
										setOpenidToken(e.target.value)
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8,
										},
									}}
								/>
							</span>
						</Grid>
					</Grid>
				</Grid>
				{/**/}
				{/*isCloud ? null : */}
				<Grid item xs={12} style={{ marginTop: 20 }} >
					<Typography variant="h4" style={{ textAlign: "left", color: "rgba(241, 241, 241, 1)", fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontSize: 24, fontWeight: 600, }}>SAML SSO (v1.1)</Typography>
					<Typography variant="body2" style={{ textAlign: "left", marginTop: 8, color: "rgba(241, 241, 241, 1)", fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontSize: 16, fontWeight: 400, color: "rgba(158, 158, 158, 1)" }} color="textSecondary">
						IdP URL for Shuffle SAML/SSO: <Link to="/api/v1/login_openid" style={{ color: "rgba(255, 132, 68, 1)", textDecoration: "none" }}>{`${globalUrl}/api/v1/login_sso`}</Link>
					</Typography>
					<Grid container style={{ marginTop: 10, }} spacing={2}>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ color: "rgba(255, 255, 255, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>SSO Entrypoint (IdP)</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "5px",
										marginRight: "15px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									multiline={true}
									rows={2}
									disabled={
										selectedOrganization.manager_orgs !== undefined &&
										selectedOrganization.manager_orgs !== null &&
										selectedOrganization.manager_orgs.length > 0
									}
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									placeholder="The entrypoint URL from your provider"
									value={ssoEntrypoint}
									onChange={(e) => {
										setSsoEntrypoint(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8,
										},
									}}
								/>
							</span>
						</Grid>
						<Grid item xs={6} style={{}}>
							<span>
								<Typography style={{ color: "rgba(255, 255, 255, 1)", fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16 }}>SSO Certificate (X509)</Typography>
								<TextField
									required
									style={{
										flex: "1",
										marginTop: "5px",
										marginRight: "15px",
										backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
									}}
									fullWidth={true}
									type="name"
									id="outlined-with-placeholder"
									margin="normal"
									variant="outlined"
									multiline={true}
									rows={2}
									placeholder="The X509 certificate to use"
									value={ssoCertificate}
									onChange={(e) => {
										setSsoCertificate(e.target.value);
									}}
									InputProps={{
										classes: {
											notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
										},
										style: {
											color: "white",
											fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
											fontWeight: 400,
											fontSize: 16,
											borderRadius: 8,
										},
									}}
								/>
							</span>
						</Grid>
					</Grid>
				</Grid>

				{isCloud ? null : (
					<Grid item xs={6} style={{}}>
						<span>
							<Typography>App Download URL</Typography>
							<TextField
								required
								style={{
									flex: "1",
									marginTop: "5px",
									marginRight: "15px",
									backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
								}}
								fullWidth={true}
								type="name"
								id="outlined-with-placeholder"
								margin="normal"
								variant="outlined"
								placeholder="A description for the organization"
								value={appDownloadUrl}
								onChange={(e) => {
									setAppDownloadUrl(e.target.value);
								}}
								InputProps={{
									classes: {
										notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
									},
									style: {
										color: "white",
										fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
										fontWeight: 400,
										fontSize: 16,
										borderRadius: 8,
									},
								}}
							/>
						</span>
					</Grid>
				)}
				{isCloud ? null : (
					<Grid item xs={6} style={{}}>
						<span>
							<Typography>App Download Branch</Typography>
							<TextField
								required
								style={{
									flex: "1",
									marginTop: "5px",
									marginRight: "15px",
									backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
								}}
								fullWidth={true}
								type="name"
								id="outlined-with-placeholder"
								margin="normal"
								variant="outlined"
								placeholder="A description for the organization"
								value={appDownloadBranch}
								onChange={(e) => {
									setAppDownloadBranch(e.target.value);
								}}
								InputProps={{
									classes: {
										notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
									},
									style: {
										color: "white",
									},
								}}
							/>
						</span>
					</Grid>
				)}
				{isCloud ? null : (
					<Grid item xs={6} style={{}}>
						<span>
							<Typography>Workflow Download URL</Typography>
							<TextField
								required
								style={{
									flex: "1",
									marginTop: "5px",
									marginRight: "15px",
									backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
								}}
								fullWidth={true}
								type="name"
								id="outlined-with-placeholder"
								margin="normal"
								variant="outlined"
								placeholder="A description for the organization"
								value={workflowDownloadUrl}
								onChange={(e) => {
									setWorkflowDownloadUrl(e.target.value);
								}}
								InputProps={{
									classes: {
										notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
									},
									style: {
										color: "white",
									},
								}}
							/>
						</span>
					</Grid>
				)}
				{isCloud ? null : (
					<Grid item xs={6} style={{}}>
						<span>
							<Typography>Workflow Download Branch</Typography>
							<TextField
								required
								style={{
									flex: "1",
									marginTop: "5px",
									marginRight: "15px",
									backgroundColor: isEditOrgTab ? "rgba(33, 33, 33, 1)" : theme.palette.inputColor,
								}}
								fullWidth={true}
								type="name"
								id="outlined-with-placeholder"
								margin="normal"
								variant="outlined"
								placeholder="A description for the organization"
								value={workflowDownloadBranch}
								onChange={(e) => {
									setWorkflowDownloadBranch(e.target.value);
								}}
								InputProps={{
									classes: {
										notchedOutline: isEditOrgTab ? null : classes.notchedOutline,
									},
									style: {
										color: "white",
									},
								}}
							/>
						</span>
					</Grid>
				)}
				{/*
					<span style={{textAlign: "center"}}>
						{expanded ? 
							<ExpandLessIcon />
							:
							<ExpandMoreIcon />
						}
					</span>
					*/}
			</Grid>
			<div style={{ textalign: "center", marginTop: "20px" }}>
				{orgSaveButton}
			</div>
		</div>
	)
}

export default OrgHeaderexpandedNew;
