import React from 'react';

import './i18n';
import './index.css';
import App from './client/App';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import history from './client/components/history.jsx';


/* 
 * Server Side Rendering (with bugs)
 */
//const root = createRoot(rootElement);
//const rootElement = document.getElementById("root")
//const renderedElement = 
//  <React.Fragment>
//	<BrowserRouter history={history}>
//    	<App />
//	</BrowserRouter> 
//  </React.Fragment>
//const onRecoverableError = (error) => {
//	console.log("Found error: ", error)
//}
//const root = hydrateRoot(rootElement, renderedElement, onRecoverableError)
//root.render(renderedElement)

/*
 * Client Side Rendering
 */ 
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <React.Fragment>
	<BrowserRouter history={history}>
    	<App />
	</BrowserRouter>
  </React.Fragment>
);

serviceWorker.unregister();
