import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Billing from "./Billing.jsx";
import Priorities from "./Priorities.jsx";
import Branding from "./Branding.jsx";
import AnalyticsTab from './AnalyticsTab.jsx';
import EditOrgTab from './EditOrgTab.jsx';
import CloudSyncTab from './CloudSyncTab.jsx';
import { ToastContainer, toast } from "react-toastify";

const OrganizationTab = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        userdata,
        globalUrl,
        serverside,
        isCloud,
        checkLogin,
        notifications,
        setNotifications,
        stripeKey, setSelectedOrganization,
        selectedStatus, setSelectedStatus,
        selectedOrganization, handleGetOrg, 
        handleStatusChange, handleEditOrg,
    } = props;

    const [selectedTab, setSelectedTab] = useState('editdetails');
    const [organizationFeatures, setOrganizationFeatures] = useState({});
    const [billingInfo, setBillingInfo] = useState({});
    const [orgRequest, setOrgRequest] = React.useState(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tabName = queryParams.get('admin_tab');
        if (tabName) {
            const decodedTabName = decodeURIComponent(tabName);
            setSelectedTab(decodedTabName);
        }
    }, [location.search]);

    const handleTabClick = (tabName) => {
        const formattedTabName = tabName.toLowerCase().replace(/[\s&]+/g, '');
        const encodedTabName = encodeURIComponent(formattedTabName);
        setSelectedTab(formattedTabName);
        document.title = `Shuffle - admin - ${formattedTabName}`;
        navigate(`?admin_tab=${encodedTabName}`);
    };

    const renderContent = () => {
        switch (selectedTab) {
            case 'editdetails':
                return <EditOrgTab selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} handleStatusChange={handleStatusChange} handleEditOrg={handleEditOrg} userdata={userdata} globalUrl={globalUrl} serverside={serverside} handleGetOrg={handleGetOrg} setSelectedOrganization={setSelectedOrganization} selectedOrganization={selectedOrganization} />;
            case 'cloudsync':
                return <CloudSyncTab isCloud={isCloud} userdata={userdata} globalUrl={globalUrl} serverside={serverside} />;
            case 'priorities':
                return (
                    <Priorities
                        isCloud={isCloud}
                        userdata={userdata}
                        globalUrl={globalUrl}
                        checkLogin={checkLogin}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        clickedFromOrgTab={true}
                    />
                );
            case 'billingstats':
                return (
                    <Billing
                        isCloud={true}
                        userdata={userdata}
                        setSelectedOrganization={setSelectedOrganization}
                        globalUrl={globalUrl}
                        selectedOrganization={selectedOrganization}
                        billingInfo={billingInfo}
                        stripeKey={stripeKey}
                        handleGetOrg={handleGetOrg}
                        clickedFromOrgTab={true}
                    />
                );
            case 'branding(beta)':
                return <Branding
                    isCloud={isCloud}
                    userdata={userdata}
                    globalUrl={globalUrl}
                    handleGetOrg={handleGetOrg}
                    selectedOrganization={selectedOrganization}
                    clickedFromOrgTab={true}
                    setSelectedOrganization={setSelectedOrganization}
                />;
            case 'analytics':
                return <AnalyticsTab userdata={userdata} globalUrl={globalUrl} />;
            default:
                return null;
        }
    };

    return (
        <div style={{ height: "auto", color: '#FFFFFF', backgroundColor: '#212121', borderRadius: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', borderBottom: '1px solid #494949' }}>
                {['Edit Details', 'Cloud Sync', 'Priorities', 'Billing & Stats', 'Branding (Beta)', 'Analytics'].map((tabName) => (
                    <div
                        key={tabName}
                        onClick={() => handleTabClick(tabName.toLowerCase().replace(/[\s&]+/g, ''))}
                        style={{
                            padding: '28px 0',
                            borderBottom: selectedTab === tabName.toLowerCase().replace(/[\s&]+/g, '') ? '2px solid #FF8444' : 'none',
                            cursor: 'pointer',
                            fontWeight: selectedTab === tabName.toLowerCase().replace(/[\s&]+/g, '') ? 'bold' : 'normal',
                            // flexBasis: '16%'
                        }}
                    >
                        <span style={{ color: selectedTab === tabName.toLowerCase().replace(/[\s&]+/g, '') ? '#FF8444' : '#FFFFFF' }}>{tabName}</span>
                    </div>
                ))}
            </div>
            {renderContent()}
        </div>
    );
};

export default OrganizationTab;