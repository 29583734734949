import React, { useEffect } from 'react';
import theme from "../theme.jsx";
import { toast } from "react-toastify" ;
import { Divider, List, ListItem, ListItemText, Button } from '@material-ui/core';

const SchedulesTab = (props) => {
  const {globalUrl,users} = props
  const [schedules, setSchedules] = React.useState([]);

  useEffect(()=>{getSchedules();},[]); 
  
  const textColor = "#9E9E9E !important";
  
  const getSchedules = () => {
    fetch(globalUrl + "/api/v1/workflows/schedules", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for apps :O!");
          return;
        }

        return response.json();
      })
      .then((responseJson) => {
        setSchedules(responseJson);
      })
      .catch((error) => {
        toast(error.toString());
      });
  };

  const deleteSchedule = (data) => {
    // FIXME - add some check here ROFL
    console.log("INPUT: ", data);

    // Just use this one?
    const url =
      globalUrl +
      "/api/v1/workflows/" +
      data["workflow_id"] +
      "/schedule/" +
      data.id;
    console.log("URL: ", url);
    fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) =>
        response.json().then((responseJson) => {
          console.log("RESP: ", responseJson);
          if (responseJson["success"] === false) {
            toast("Failed stopping schedule");
          } else {
            setTimeout(() => {
              getSchedules();
            }, 1500);
            //toast("Successfully stopped schedule!")
          }
        })
      )
      .catch((error) => {
        console.log("Error in userdata: ", error);
      });
  };

  return (
    <div style={{width:  1030, padding:27, height: 1200, backgroundColor: '#212121', borderRadius: '16px',}}>
      <div>
        <h2 style={{ marginBottom: 8, marginTop: 0  }}>Schedules</h2>
        <span style={{color:textColor}}>
          Schedules used in Workflows. Makes locating and control easier.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/docs/organizations#schedules"
            style={{ color:"#FF8444" }}
          >
            Learn more
          </a>
        </span>
      </div>
      {/* <Divider
        style={{
          marginTop: 20,
          marginBottom: 20,
          backgroundColor: theme.palette.inputColor,
        }}
      /> */}
      <List style={{borderRadius: 8, color: textColor, border:"1px solid #494949", marginTop:24}}>
        <ListItem style={{width:"100%", borderBottom:"1px solid #494949"}}>
          <ListItemText
            primary="Interval"
            style={{ maxWidth: 100, minWidth: 100 }}
          />
          <ListItemText
            primary="Environment"
            style={{ maxWidth: 150, minWidth: 150 }}
          />
          <ListItemText
            primary="Workflow"
            style={{ maxWidth: 215, minWidth: 215 }}
          />
          <ListItemText
            primary="Argument"
            style={{ minWidth: 200, maxWidth: 200, overflow: "hidden" }}
          />
          <ListItemText primary="Actions" />
          <ListItemText primary="Delegation" />
        </ListItem>
        {schedules &&
          schedules.map((schedule, index) => {
            const bgColor = index % 2 === 0 ? "#1f2023" : "#27292d";

            return (
              <ListItem key={index} style={{ backgroundColor: bgColor }}>
                <ListItemText
                  style={{ maxWidth: 200, minWidth: 200 }}
                  primary={
                    schedule.environment === "cloud" || schedule.environment === "" || schedule.frequency.length > 0
                      ? schedule.frequency
                      : <span>{schedule.seconds} seconds</span>
                  }
                />
                <ListItemText
                  style={{ maxWidth: 150, minWidth: 150 }}
                  primary={schedule.environment}
                />
                <ListItemText
                  style={{ maxWidth: 315, minWidth: 315 }}
                  primary={
                    <a
                      style={{ textDecoration: "none", color: "#f85a3e" }}
                      href={`/workflows/${schedule.workflow_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {schedule.workflow_id}
                    </a>
                  }
                />
                <ListItemText
                  primary={schedule.argument.replaceAll('\\\"', '\"')}
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    overflow: "hidden",
                  }}
                />
                <ListItemText>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => deleteSchedule(schedule)}
                  >
                    Stop schedule
                  </Button>
                </ListItemText>
              </ListItem>
            );
          })}
      </List>
    </div>
  );
};

export default SchedulesTab;
