import React, { useEffect } from 'react';
import { } from '@material-ui/core';
import {
    Edit as EditIcon,
    SelectAll as SelectAllIcon,
    Delete as DeleteIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import theme from "../theme.jsx";

import {
    Divider, List, ListItem, ListItemText, IconButton, Tooltip, Chip, Checkbox,
    Typography,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from "@mui/material";

const AppAuthTab = (props) => {
    const { globalUrl, userdata, isCloud, selectedOrganization } = props;
    const [selectedAuthentication, setSelectedAuthentication] = React.useState({});
    const [authentication, setAuthentication] = React.useState([]);
    const [selectedUserModalOpen, setSelectedUserModalOpen] = React.useState(false);
    const [authenticationFields, setAuthenticationFields] = React.useState([]);
    const [selectedAuthenticationModalOpen, setSelectedAuthenticationModalOpen] = React.useState(false);

    const changeDistribution = (data) => {
        //changeDistributed(data, !isDistributed)
        console.log("Should change distribution to be shared among suborgs")

        editAuthenticationConfig(data.id, "suborg_distribute")
    }
    useEffect(() => {
        getAppAuthentication();
    }, [])

    const getAppAuthentication = () => {
        fetch(globalUrl + "/api/v1/apps/authentication", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log("Status not 200 for apps :O!");
                    return;
                }

                return response.json();
            })
            .then((responseJson) => {
                if (responseJson.success === true) {
                    console.log("responseJsondata", responseJson.data)
                    console.log("responseJson", responseJson)
                    setAuthentication(responseJson.data);
                } else {
                    toast("Failed getting authentications");
                }
            })
            .catch((error) => {
                toast(error.toString());
            });
    };

    const updateAppAuthentication = (field) => {
        setSelectedAuthenticationModalOpen(true);
        setSelectedAuthentication(field);
        //{selectedAuthentication.fields.map((data, index) => {
        var newfields = [];
        for (var key in field.fields) {
            newfields.push({
                key: field.fields[key].key,
                value: "",
            });
        }
        setAuthenticationFields(newfields);
    };
    const saveAuthentication = (authentication) => {
        const data = authentication;
        const url = globalUrl + "/api/v1/apps/authentication";

        fetch(url, {
            mode: "cors",
            method: "PUT",
            body: JSON.stringify(data),
            credentials: "include",
            crossDomain: true,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson["success"] === false) {
                        // Check if .reason exists
                        if (responseJson.reason !== undefined) {
                            toast("Failed changing authentication: " + responseJson.reason);
                        } else {
                            toast("Failed changing authentication");
                        }
                    } else {
                        getAppAuthentication();


                        setSelectedAuthentication({});
                        setSelectedAuthenticationModalOpen(false);
                    }
                })
            )
            .catch((error) => {
                toast("Err: " + error.toString());
            });
    };

    const deleteAuthentication = (data) => {
        toast("Deleting auth " + data.label);

        // Just use this one?
        const url = globalUrl + "/api/v1/apps/authentication/" + data.id;
        console.log("URL: ", url);
        fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson.success === false) {
                        toast("Failed deleting auth");
                    } else {
                        // Need to wait because query in ES is too fast
                        setTimeout(() => {
                            getAppAuthentication();
                        }, 1000);
                        //toast("Successfully deleted authentication!")
                    }
                })
            )
            .catch((error) => {
                console.log("Error in userdata: ", error);
            });
    };

    const editAuthenticationConfig = (id, parentAction) => {
        const data = {
            id: id,
            action: parentAction !== undefined && parentAction !== null ? parentAction : "assign_everywhere",
        }

        const url = globalUrl + "/api/v1/apps/authentication/" + id + "/config";

        fetch(url, {
            mode: "cors",
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
            crossDomain: true,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson["success"] === false) {
                        toast("Failed overwriting appauth");
                    } else {
                        toast("Successfully updated auth!");
                        setSelectedUserModalOpen(false);
                        setTimeout(() => {
                            getAppAuthentication();
                        }, 1000);
                    }
                })
            )
            .catch((error) => {
                toast("Err: " + error.toString());
            });
    };

    const editAuthenticationModal = selectedAuthenticationModalOpen ? (
        <Dialog
            open={selectedAuthenticationModalOpen}
            onClose={() => {
                setSelectedAuthenticationModalOpen(false);
            }}
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.surfaceColor,
                    color: "white",
                    minWidth: "800px",
                    minHeight: "320px",
                },
            }}
        >
            <DialogTitle>
                <span style={{ color: "white" }}>
                    Edit authentication for {selectedAuthentication.app.name.replaceAll("_", " ")} (
                    {selectedAuthentication.label})
                </span>
                <Typography variant="body1" color="textSecondary" style={{ marginTop: 10 }}>
                    You can <b>not</b> see the previous values for an authentication while editing. This is to keep your data secure. You can overwrite one- or multiple fields at a time.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography style={{ marginBottom: 0, marginTop: 10 }}>
                    Authentication Label
                </Typography>
                <TextField
                    style={{
                        backgroundColor: theme.palette.inputColor,
                        marginTop: 0,
                    }}
                    InputProps={{
                        style: {
                            height: 50,
                            color: "white",
                        },
                    }}
                    color="primary"
                    required
                    fullWidth={true}
                    placeholder={selectedAuthentication.label}
                    defaultValue={selectedAuthentication.label}
                    type="text"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => {
                        selectedAuthentication.label = e.target.value
                    }}
                />

                <Divider />
                {selectedAuthentication.type === "oauth" || selectedAuthentication.type === "oauth2" || selectedAuthentication.type === "oauth2-app" ?
                    <div>
                        <Typography variant="body1" color="textSecondary" style={{ marginBottom: 0, marginTop: 10 }}>
                            Only the name and url can be modified for Oauth2/OpenID connect. Please remake the authentication if you want to change the other fields like Client ID, Secret, Scopes etc.
                        </Typography>
                    </div>
                    : null}

                {selectedAuthentication.fields.map((data, index) => {
                    var fieldname = data.key.replaceAll("_", " ")
                    if (fieldname.endsWith(" basic")) {
                        fieldname = fieldname.substring(0, fieldname.length - 6)
                    }

                    if (selectedAuthentication.type === "oauth" || selectedAuthentication.type === "oauth2" || selectedAuthentication.type === "oauth2-app") {
                        if (selectedAuthentication.fields[index].key !== "url") {
                            return null
                        }
                    }


                    //console.log("DATA: ", data, selectedAuthentication)
                    return (
                        <div key={index}>
                            <Typography style={{ marginBottom: 0, marginTop: 10 }}>
                                {fieldname}
                            </Typography>
                            <TextField
                                style={{
                                    backgroundColor: theme.palette.inputColor,
                                    marginTop: 0,
                                }}
                                InputProps={{
                                    style: {
                                        height: 50,
                                        color: "white",
                                    },
                                }}
                                color="primary"
                                required
                                fullWidth={true}
                                placeholder={fieldname}
                                type="text"
                                id={`authentication-${index}`}
                                margin="normal"
                                variant="outlined"
                                onChange={(e) => {
                                    authenticationFields[index].value = e.target.value;
                                    setAuthenticationFields(authenticationFields);
                                }}
                            />
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ borderRadius: "0px" }}
                    onClick={() => setSelectedAuthenticationModalOpen(false)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    style={{ borderRadius: "0px" }}
                    onClick={() => {
                        var error = false;
                        var fails = 0
                        for (var key in authenticationFields) {
                            const item = authenticationFields[key];
                            if (item.value.length === 0) {
                                fails += 1
                                console.log("ITEM: ", item);
                                // var currentnode = cy.getElementById(data.id)
                                var textfield = document.getElementById(
                                    `authentication-${key}`
                                );
                                if (textfield !== null && textfield !== undefined) {
                                    console.log("HANDLE ERROR FOR KEY ", key);
                                }
                                error = true;
                            }
                        }

                        if (selectedAuthentication.type === "oauth" || selectedAuthentication.type === "oauth2" || selectedAuthentication.type === "oauth2-app") {
                            selectedAuthentication.fields = []
                        }

                        if (error && fails === authenticationFields.length) {
                            toast("Updating auth with new name only")
                            saveAuthentication(selectedAuthentication);
                        } else {
                            toast("Saving new version of this authentication");
                            selectedAuthentication.fields = authenticationFields;
                            saveAuthentication(selectedAuthentication);
                        }
                    }}
                    color="primary"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;

    return (
        <div style={{ width: 1030, padding: 27, height: "auto", backgroundColor: '#212121', borderRadius: '16px', }}>
            <div style={{}}>
                <h2 style={{ marginBottom: 8, marginTop: 0, color: "#FFFFFF" }}>App Authentication</h2>
                <span style={{}}>
                    Control the authentication options for individual apps.
                </span>
                &nbsp;
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/docs/organizations#app_authentication"
                    style={{ color: "#FF8444" }}
                >
                    Learn more about App Authentication
                </a>
                <Button
                    style={{ backgroundColor: 'rgba(255, 132, 68, 0.2)', color: "#FF8444", marginLeft:180, top:-20, borderRadius: 200, width: 162, height: 40, boxShadow:'none', }}
                    variant="contained"
                    color="primary"
                    // onClick={() => setModalOpen(true)}
                >
                    Add App Auth
                </Button>
            </div>
            {/* <Divider
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                    backgroundColor: theme.palette.inputColor,
                }}
            /> */}
            <List style={{ borderRadius: 8, border: "1px solid #494949", marginTop: 24 }}>
                <ListItem style={{ width: "100%", borderBottom: "1px solid #494949" }}>
                    <ListItemText
                        primary="Icon"
                        style={{ minWidth: 75, maxWidth: 75 }}
                    />
                    <ListItemText
                        primary="Label"
                        style={{ minWidth: 140, maxWidth: 140 }}
                    />
                    <ListItemText
                        primary="App Name"
                        style={{ minWidth: 120, maxWidth: 120, marginLeft: 5 }}
                    />
                    <ListItemText
                        primary="Workflows"
                        style={{ minWidth: 100, maxWidth: 100, overflow: "hidden" }}
                    />
                    <ListItemText
                        primary="Fields"
                        style={{ minWidth: 105, maxWidth: 105, overflow: "hidden" }}
                    />
                    <ListItemText
                        primary="Edited"
                        style={{ minWidth: 230, maxWidth: 230, overflow: "hidden" }}
                    />
                    <ListItemText
                        primary="Actions"
                        style={{ minWidth: 123, maxWidth: 123, textAlign: "center" }}
                    />
                    <ListItemText
                        primary="Distribution"
                        style={{ textAlign: "center" }}
                    />
                </ListItem>

                {authentication === undefined || authentication === null
                    ? null
                    : authentication.map((data, index) => {
                        var bgColor = "#212121";
                        if (index % 2 === 0) {
                            bgColor = "#1A1A1A";
                        }

                        //console.log("Auth data: ", data)
                        if (data.type === "oauth2") {
                            data.fields = [
                                {
                                    key: "url",
                                    value: "Secret. Replaced during app execution!",
                                },
                                {
                                    key: "client_id",
                                    value: "Secret. Replaced during app execution!",
                                },
                                {
                                    key: "client_secret",
                                    value: "Secret. Replaced during app execution!",
                                },
                                {
                                    key: "scope",
                                    value: "Secret. Replaced during app execution!",
                                },
                            ];
                        }

                        const isDistributed = data.suborg_distributed === true ? true : false;

                        return (
                            <ListItem key={index} style={{ backgroundColor: bgColor }}>
                                <ListItemText
                                    primary=<img
                                        alt=""
                                        src={data.app.large_image}
                                        style={{
                                            maxWidth: 50,
                                            borderRadius: theme.palette?.borderRadius,
                                        }}
                                    />
                                    style={{ minWidth: 75, maxWidth: 75 }}
                                />
                                <ListItemText
                                    primary={data.label}
                                    style={{
                                        minWidth: 140,
                                        maxWidth: 140,
                                        overflow: "hidden",
                                    }}
                                />
                                <ListItemText
                                    primary={data.app.name.replaceAll("_", " ")}
                                    style={{ minWidth: 120, maxWidth: 120, marginLeft: 10 }}
                                />
                                <ListItemText
                                    primary={
                                        data.workflow_count === null ? 0 : data.workflow_count
                                    }
                                    style={{
                                        minWidth: 60,
                                        maxWidth: 60,
                                        textAlign: "center",
                                        overflow: "hidden",
                                    }}
                                />
                                <ListItemText
                                    primary={
                                        data.fields === null || data.fields === undefined
                                            ? ""
                                            : data.fields
                                                .map((data) => {
                                                    return data.key;
                                                })
                                                .join(", ")
                                    }
                                    style={{
                                        minWidth: 85,
                                        maxWidth: 85,
                                        overflow: "auto",
                                        marginLeft: 35,
                                    }}
                                />
                                <ListItemText
                                    style={{
                                        maxWidth: 230,
                                        minWidth: 230,
                                        overflow: "hidden",
                                        marginLeft: 22,
                                    }}
                                    primary={new Date(data.edited * 1000).toISOString()}
                                />
                                <ListItemText>
                                    <IconButton
                                        onClick={() => {
                                            updateAppAuthentication(data);
                                        }}
                                        disabled={data.org_id !== selectedOrganization.id ? true : false}
                                    >
                                        <EditIcon color="secondary" />
                                    </IconButton>
                                    {data.defined ? (
                                        <Tooltip
                                            color="primary"
                                            title="Set for EVERY instance of this App being used in this organization"
                                            placement="top"
                                        >
                                            <IconButton
                                                style={{ marginRight: 10 }}
                                                disabled={data.defined === false || data.org_id !== selectedOrganization.id ? true : false}
                                                onClick={() => {
                                                    editAuthenticationConfig(data.id);
                                                }}
                                            >
                                                <SelectAllIcon
                                                    color={"secondary"}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            color="primary"
                                            title="Must edit before you can set in all workflows"
                                            placement="top"
                                        >
                                            <IconButton
                                                style={{}}
                                                onClick={() => { }}
                                                disabled={data.org_id !== selectedOrganization.id ? true : false}
                                            >
                                                <SelectAllIcon
                                                    color="secondary"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <IconButton
                                        style={{ marginLeft: 0, }}
                                        disabled={data.org_id !== selectedOrganization.id ? true : false}
                                        onClick={() => {
                                            deleteAuthentication(data);
                                        }}
                                    >
                                        <DeleteIcon color="secondary" />
                                    </IconButton>
                                </ListItemText>
                                <ListItemText>
                                    {selectedOrganization.id !== undefined && data.org_id !== selectedOrganization.id ?
                                        <Tooltip
                                            title="Parent organization controlled auth. You can use, but not modify this auth. Contact an admin of your parent organization if you need changes to this."
                                            placement="top"
                                        >
                                            <Chip
                                                label={"Parent"}
                                                variant="contained"
                                                color="secondary"
                                            />
                                        </Tooltip>
                                        :
                                        <Tooltip
                                            title="Distributed to sub-organizations. This means the sub organizations can use this authentication, but not modify it."
                                            placement="top"
                                        >
                                            <Checkbox
                                                disabled={selectedOrganization.creator_org !== undefined && selectedOrganization.creator_org !== null && selectedOrganization.creator_org !== "" ? true : false}
                                                checked={isDistributed}
                                                color="secondary"
                                                onClick={() => {
                                                    changeDistribution(data, !isDistributed)
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                </ListItemText>
                            </ListItem>
                        );
                    })}
            </List>
            {editAuthenticationModal}
        </div>
    );
};

export default AppAuthTab;
