import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import OrganizationTab from './OrganizationTab.jsx';
import UserManagmentTab from './UserManagmentTab.jsx';
import CacheView from "../components/CacheView.jsx";
import Files from "../components/Files.jsx";
import AppAuthTab from "../components/AppAuthTab.jsx";
import SchedulesTab from "../components/SchedulesTab.jsx";
import EnvironmentTab from "../components/EnvironmentTab.jsx";
import TenantsTab from "../components/TenantsTab.jsx";

import {
    Business as BusinessIcon,
    PermIdentity as PermIdentityIcon,
    HttpsOutlined as HttpsOutlinedIcon,
    InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
    StorageOutlined as StorageOutlinedIcon,
    AccessTimeOutlined as AccessTimeOutlinedIcon,
    FmdGoodOutlined as FmdGoodOutlinedIcon,
    GroupOutlined as GroupOutlinedIcon
} from '@mui/icons-material';

const AdminNavBar = (props) => {
    const location = useLocation();
    const { globalUrl, userdata, isCloud, handleStatusChange, selectedStatus, setSelectedStatus, handleEditOrg, serverside, notifications, handleGetOrg, orgId, checkLogin, setNotifications, stripeKey, setSelectedOrganization, selectedOrganization } = props;
    const [selectedItem, setSelectedItem] = useState("Organization");
    const [isSelectedFiles, setIsSelectedFiles] = useState(true);
    const [isSelectedDataStore, setIsSelectedDataStore] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tabName = queryParams.get('tab');

        if (tabName) {
            setSelectedItem(tabName.charAt(0).toUpperCase() + tabName.slice(1));
        } else {
            // If no tab is specified, default to "Organization" tab
            setSelectedItem("Organization");
        }
    }, [location.search]);

    const items = [
        { iconSrc: <BusinessIcon />, alt: "Organization Icon", text: "Organization", component: OrganizationTab, props: { globalUrl, selectedStatus, setSelectedStatus, handleStatusChange, handleEditOrg, handleGetOrg, userdata, isCloud, serverside, notifications, checkLogin, setNotifications, stripeKey, setSelectedOrganization, selectedOrganization } },
        { iconSrc: <PermIdentityIcon />, alt: "Users Icon", text: "Users", component: UserManagmentTab, props: { globalUrl, userdata, serverside } },
        { iconSrc: <HttpsOutlinedIcon />, alt: "Apps Auth Icon", text: "Apps_auth", component: AppAuthTab, props: { globalUrl, userdata, isCloud, selectedOrganization } },
        { iconSrc: <InsertDriveFileOutlinedIcon />, alt: "Files Icon", text: "Files", component: Files, props: { isCloud, globalUrl, userdata, serverside, selectedOrganization, isSelectedFiles } },
        { iconSrc: <StorageOutlinedIcon />, alt: "Datastore Icon", text: "Datastore", component: CacheView, props: { globalUrl, serverside, isSelectedDataStore, orgId } },
        { iconSrc: <AccessTimeOutlinedIcon />, alt: "Schedules Icon", text: "Schedules", component: SchedulesTab, props: { globalUrl, userdata, isCloud, serverside } },
        { iconSrc: <FmdGoodOutlinedIcon />, alt: "Environments Icon", text: "Environments", component: EnvironmentTab, props: { globalUrl, userdata, isCloud } },
        { iconSrc: <GroupOutlinedIcon />, alt: "Tenants Icon", text: "Tenants", component: TenantsTab, props: { globalUrl, userdata, serverside, selectedOrganization, setSelectedOrganization } }
    ];

    const setConfig = (newValue) => {
        setSelectedItem(newValue);
        if (newValue === "Apps Auth") {
            const tabName = newValue.toLowerCase().replace(/\s+/g, '_');
            navigate(`?tab=${tabName}`, { replace: true });
        } else {
            const tabName = newValue.toLowerCase().replace(/\s+/g, '_');
            navigate(`?tab=${tabName}`, { replace: true });
        }
    };

    const renderComponent = () => {
        const selectedItemData = items.find(item => item.text === selectedItem);
        if (!selectedItemData) return null;

        const ComponentToRender = selectedItemData.component;
        const componentProps = selectedItemData.props;

        return <ComponentToRender {...componentProps} />;
    };

    const defaultImage = "/images/logos/orange_logo.svg"
    const imageData =
        selectedOrganization?.image === undefined || selectedOrganization?.image.length === 0
            ? defaultImage
            : selectedOrganization?.image;

    return (
        <div style={{ display: 'flex', }}>
            <div style={{ flexDirection: 'column', marginRight: '24px', width: 220, }}>
                <nav style={{ padding: '25px', height: 523, fontSize: '16px', borderRadius: 16, background: '#212121', color: '#9CA3AF' }}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <img loading="lazy" src={imageData} alt="Logo" style={{ width: '30px', borderRadius: 8, height: '30px', marginRight: '8px' }} />
                        <div style={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            color: "#FFFFFF",
                            fontWeight: 400,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                        }}>{selectedOrganization?.name}</div>
                    </div>
                    <div style={{ borderTop: '1px solid #494949', marginTop: 23 }} />
                    {items.map((item) => (
                        <div
                            key={item.text}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                                marginTop: '20px',
                                cursor: 'pointer',
                                color: selectedItem === item.text ? "#FFFFFF" : "#9E9E9E",
                                borderLeft: selectedItem === item.text ? '3px solid rgba(255, 132, 68, 1)' : 'none',
                                borderTopLeftRadius: selectedItem === item.text ? '2.5px' : null,
                                borderBottomLeftRadius: selectedItem === item.text ? '2.5px' : null,
                                paddingLeft: selectedItem === item.text ? 10 : 3,
                                fontWeight: selectedItem === item.text ? 200 : 'normal'
                            }}
                            onClick={() => setConfig(item.text)}
                        >
                            {item.iconSrc}
                            <div style={{ flex: '1', color: selectedItem === item.text ? "#FFFFFF" : "#9E9E9E" }}>
                                {item.text.replace(/_/g, ' ')}
                            </div>
                        </div>
                    ))}
                </nav>
            </div>
            <div>{renderComponent()}</div>
        </div>
    );
};

export default AdminNavBar;
