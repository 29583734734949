import React, { useEffect } from 'react';
import theme from "../theme.jsx";
import {
    Tooltip,
    Typography,
    Switch,
    TextField,
    Button,
    ButtonGroup,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from "@mui/material";
import {
    FileCopy as FileCopyIcon,
    CheckCircle as CheckCircleIcon,
    Cached as CachedIcon,
    Cloud as CloudIcon,
    Cancel as CancelIcon,
    Help as HelpIcon,
} from "@mui/icons-material";
import { toast } from 'react-toastify';

const EnvironmentTab = (props) => {
    const { globalUrl, isCloud, userdata } = props;
    const [environments, setEnvironments] = React.useState([]);
    const [showArchived, setShowArchived] = React.useState(false);
    const [modalUser, setModalUser] = React.useState({});
    const [loginInfo, setLoginInfo] = React.useState("");
    const [modalOpen, setModalOpen] = React.useState(false);

    useEffect(() => {
        getEnvironments();
        setModalUser({});
    }, []);

    const changeModalData = (field, value) => {
        modalUser[field] = value;
    };

    // Horrible frontend fix for environments
    const setDefaultEnvironment = (environment) => {
        // FIXME - add more checks to this
        toast("Setting default env to " + environment.name);
        var newEnv = [];
        for (var key in environments) {
            if (environments[key].id == environment.id) {
                if (environments[key].archived) {
                    toast("Can't set archived to default");
                    return;
                }

                environments[key].default = true;
            } else if (
                environments[key].default == true &&
                environments[key].id !== environment.id
            ) {
                environments[key].default = false;
            }

            newEnv.push(environments[key]);
        }

        // Just use this one?
        const url = globalUrl + "/api/v1/setenvironments";
        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(newEnv),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson["success"] === false) {
                        toast(responseJson.reason);
                        setTimeout(() => {
                            getEnvironments();
                        }, 1500);
                    } else {
                        setLoginInfo("");
                        setModalOpen(false);
                        setTimeout(() => {
                            getEnvironments();
                        }, 1500);
                    }
                }),
            )
            .catch((error) => {
                console.log("Error in backend data: ", error);
            });
    };

    const rerunCloudWorkflows = (environment) => {
        toast("Starting execution reruns. This can run in the background.");
        fetch(`${globalUrl}/api/v1/environments/${environment.id}/rerun`, {
          method: "GET",
          credentials: "include",
        })
          .then((response) => {
            if (response.status !== 200) {
              console.log("Status not 200 for apps :O!");
              return;
            } else {
              toast(response.reason);
              //toast("Aborted all dangling workflows");
            }
    
            return response.json();
          })
          .then((responseJson) => {
            console.log("Got response for execution: ", responseJson);
            //console.log("RESPONSE: ", responseJson)
            //setFiles(responseJson)
          })
          .catch((error) => {
            //toast(error.toString())
          });
      };

    const getEnvironments = () => {
        fetch(globalUrl + "/api/v1/getenvironments", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log("Status not 200 for apps :O!");
                    return;
                }

                return response.json();
            })
            .then((responseJson) => {
                setEnvironments(responseJson);

                // Helper info for users in case they have a large queue and don't know about queue flushing
                if (responseJson !== undefined && responseJson !== null && responseJson.length > 0) {
                    for (var i = 0; i < responseJson.length; i++) {
                        const env = responseJson[i];

                        // Check if queuesize is too large
                        if (env.queue !== undefined && env.queue !== null && env.queue > 100) {
                            toast("Queue size for " + env.name + " is very large. We recommend you to reduce it by flushing the queue before continuing.");
                            break
                        }
                    }
                }
            })
            .catch((error) => {
                toast(error.toString());
            });
    };

    const flushQueue = (name) => {
        // Just use this one?
        const url = globalUrl + "/api/v1/flush_queue";
        fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson["success"] === false) {
                        toast(responseJson.reason);
                        getEnvironments();
                    } else {
                        setLoginInfo("");
                        setModalOpen(false);
                        getEnvironments();
                    }
                }),
            )
            .catch((error) => {
                console.log("Error when deleting: ", error);
            });
    };


    const deleteEnvironment = (environment) => {
        // FIXME - add some check here ROFL
        //const name = environment.name

        //toast("Modifying environment " + name)
        //var newEnv = []
        //for (var key in environments) {
        //	if (environments[key].Name == name) {
        //		if (environments[key].default) {
        //			toast("Can't modify the default environment")
        //			return
        //		}

        //		if (environments[key].type === "cloud" && !environments[key].archived) {
        //			toast("Can't modify cloud environments")
        //			return
        //		}

        //		environments[key].archived = !environments[key].archived
        //	}

        //	newEnv.push(environments[key])
        //}
        const id = environment.id;

        //toast("Modifying environment " + environment.Name)
        var newEnv = [];
        for (var key in environments) {
            if (environments[key].id == id) {
                if (environments[key].default) {
                    toast("Can't modify the default environment");
                    return;
                }

                if (environments[key].type === "cloud" && !environments[key].archived) {
                    toast("Can't modify cloud environments");
                    return;
                }

                environments[key].archived = !environments[key].archived;
            }

            newEnv.push(environments[key]);
        }

        // Just use this one?
        const url = globalUrl + "/api/v1/setenvironments";
        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(newEnv),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson["success"] === false) {
                        toast(responseJson.reason);
                        getEnvironments();
                    } else {
                        setLoginInfo("");
                        setModalOpen(false);
                        getEnvironments();
                    }
                }),
            )
            .catch((error) => {
                console.log("Error when deleting: ", error);
            });
    };

    const abortEnvironmentWorkflows = (environment) => {
        //console.log("Aborting all workflows started >10 minutes ago, not finished");
        toast(
            "Clearing the queue - this may take some time. A new will show up when finished.",
        );

        fetch(
            `${globalUrl}/api/v1/environments/${environment.id}/stop?deleteall=true`,
            {
                method: "GET",
                credentials: "include",
            },
        )
            .then((response) => {
                if (response.status !== 200) {
                    console.log("Status not 200 for apps :O!");
                    toast("Failed aborting dangling workflows");
                    return;
                } else {
                    toast("Successfully cleared the queue");

                    getEnvironments();
                }

                return response.json();
            })
            .then((responseJson) => {
                console.log("Got response for execution: ", responseJson);
                //console.log("RESPONSE: ", responseJson)
                //setFiles(responseJson)
            })
            .catch((error) => {
                //toast(error.toString())
            });
    };

    const submitEnvironment = (data) => {
        // FIXME - add some check here ROFL
        environments.push({
            name: data.environment,
            type: "onprem",
        });

        // Just use this one?
        var baseurl = globalUrl;
        const url = baseurl + "/api/v1/setenvironments";
        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(environments),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson["success"] === false) {
                        setLoginInfo("Error in input: " + responseJson.reason);
                        getEnvironments();
                    } else {
                        setLoginInfo("");
                        setModalOpen(false);
                        getEnvironments();
                    }
                }),
            )
            .catch((error) => {
                console.log("Error in userdata: ", error);
            });
    };
    const modalView = (
        <Dialog
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
            }}
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.surfaceColor,
                    color: "white",
                    minWidth: "800px",
                    minHeight: "320px",
                },
            }}
        >
            <DialogTitle>
                <span style={{ color: "white" }}>Add Environment</span>
            </DialogTitle>
            <DialogContent>
                <div>
                    Environment Name
                    <TextField
                        color="primary"
                        style={{ backgroundColor: theme.palette.inputColor }}
                        autoFocus
                        InputProps={{
                            style: {
                                height: "50px",
                                color: "white",
                                fontSize: "1em",
                            },
                        }}
                        required
                        fullWidth={true}
                        placeholder="datacenter froglantern"
                        id="environment_name"
                        margin="normal"
                        variant="outlined"
                        onChange={(event) =>
                            changeModalData("environment", event.target.value)
                        }
                    />
                </div>
                {loginInfo} {/* Assuming loginInfo is part of the relevant content */}
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ borderRadius: "0px" }}
                    onClick={() => setModalOpen(false)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    style={{ borderRadius: "0px" }}
                    onClick={() => {
                        submitEnvironment(modalUser); // Assuming modalUser is available
                    }}
                    color="primary"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );


    const textColor = "#9E9E9E !important";

    const queueSizeText = (queue) => {
        if (queue === undefined || queue === null) return 0;
        if (queue < 0) return 0;
        if (queue > 1000) return ">1000";
        return queue;
    };

    return (
        <div style={{ width: 1030, padding: 27, height: "auto", backgroundColor: '#212121', borderRadius: '16px', }}>
            {modalView}
            <div style={{ marginBottom: 20 }}>
                <h2 style={{ marginBottom: 8, marginTop: 0, color: "#ffffff" }}>Environments</h2>
                <span style={{ color: textColor }}>
                    Decides what Orborus environment to run your workflow actions. <br /> If you have scale problems, talk to our team: support@shuffler.io.&nbsp;
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/docs/organizations#environments"
                        style={{ color: "#FF8444" }}
                    >
                        Learn more
                    </a>
                </span>
            </div>
            <Button
                style={{ backgroundColor: 'rgba(255, 132, 68, 0.2)', color: "#FF8444", borderRadius: 200, textTransform: "capitalize", fontSize: 16 }}
                variant="contained"
                color="primary"
                onClick={() => setModalOpen(true)}
            >
                Add environment
            </Button>
            <Button
                style={{ backgroundColor: "#2F2F2F", borderRadius: 200, width: 81, height: 40, marginLeft: 5, marginRight: 15 }}
                variant="contained"
                color="primary"
                onClick={getEnvironments}
            >
                <CachedIcon />
            </Button>
            <Switch
                checked={showArchived}
                onChange={() => setShowArchived(!showArchived)}
            />{" "}
            Show disabled
            {/* <Divider
        style={{
          marginTop: 20,
          marginBottom: 20,
          backgroundColor: theme.palette.inputColor,
        }}
      /> */}
            <List style={{ borderRadius: 8, border: "1px solid #494949", marginTop: 24 }}>
                <ListItem style={{ width: "100%", borderBottom: "1px solid #494949" }}>
                    <ListItemText
                        primary="Type"
                        style={{ minWidth: 50, maxWidth: 50 }}
                    />
                    <ListItemText
                        primary="License"
                        style={{ minWidth: 80, maxWidth: 80 }}
                    />
                    <ListItemText
                        primary="Name"
                        style={{ minWidth: 100, maxWidth: 100 }}
                    />
                    <ListItemText
                        primary="Status"
                        style={{ minWidth: 67, maxWidth: 67 }}
                    />
                    <ListItemText
                        primary="Command"
                        style={{ minWidth: 100, maxWidth: 100 }}
                    />
                    <ListItemText
                        primary="Location"
                        style={{ minWidth: 100, maxWidth: 100 }}
                    />
                    <ListItemText
                        primary="Queue"
                        style={{ minWidth: 82, maxWidth: 82 }}
                    />
                    <ListItemText
                        primary="Default"
                        style={{ minWidth: 110, maxWidth: 110 }}
                    />
                    <ListItemText
                        primary="Actions"
                        style={{ minWidth: 150, maxWidth: 150 }}
                    />
                    <ListItemText
                        primary="Last Edited"
                        style={{ minWidth: 120, maxWidth: 120 }}
                    />
                </ListItem>
                {environments &&
                    environments.map((environment, index) => {
                        if (!showArchived && environment.archived) {
                            return null;
                        }

                        if (environment.archived === undefined) {
                            return null;
                        }

                        const bgColor = index % 2 === 0 ? "#212121" : "#1A1A1A";

                        const showCPUAlert =
                            userdata &&
                            userdata.priorities &&
                            userdata.priorities.some(
                                (prio) =>
                                    prio.name.includes("CPU") && prio.active && prio.name.endsWith(environment.Name)
                            );

                        return (
                            <span key={index}>
                                <ListItem key={index} style={{ backgroundColor: bgColor, marginLeft: 0 }}>
                                    {/* Type */}
                                    <ListItemText
                                        primary={
                                            environment.run_type === "cloud" || environment.name === "Cloud" ? (
                                                <Tooltip title="Cloud" placement="top">
                                                    <CloudIcon style={{ color: "rgba(255,255,255,0.8)" }} />
                                                </Tooltip>
                                            ) : environment.run_type === "docker" ? (
                                                <Tooltip title="Docker" placement="top">
                                                    <img src="/icons/docker.svg" style={{ width: 30, height: 30 }} />
                                                </Tooltip>
                                            ) : environment.run_type === "k8s" ? (
                                                <Tooltip title="Kubernetes" placement="top">
                                                    <img src="/icons/k8s.svg" style={{ width: 30, height: 30 }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Unknown" placement="top">
                                                    <HelpIcon style={{ color: "rgba(255,255,255,0.8)" }} />
                                                </Tooltip>
                                            )
                                        }
                                        style={{
                                            minWidth: 50,
                                            maxWidth: 50,
                                            overflow: "hidden",
                                        }}
                                    />
                                    {/* License */}
                                    <ListItemText
                                        primary={
                                            environment.licensed ? (
                                                <Tooltip title="Licensed" placement="top">
                                                    <CheckCircleIcon style={{ color: "#4caf50" }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip
                                                    title="Not licensed, and can't scale.. This may cause service disruption."
                                                    placement="top"
                                                >
                                                    <a href="/admin?tab=billing" target="_blank" rel="noopener noreferrer">
                                                        <CancelIcon style={{ color: "#f85a3e" }} />
                                                    </a>
                                                </Tooltip>
                                            )
                                        }
                                        style={{
                                            minWidth: 60,
                                            maxWidth: 60,
                                            textAlign: "center",
                                            overflow: "hidden",
                                        }}
                                    />
                                    {/* Name */}
                                    <ListItemText
                                        primary={environment.Name}
                                        style={{
                                            minWidth: 110,
                                            maxWidth: 110,
                                            marginLeft: 12,
                                            overflow: "hidden",
                                        }}
                                    />
                                    {/* Status */}
                                    <ListItemText
                                        primary={
                                            environment.Type !== "cloud" ? (
                                                environment.running_ip && environment.running_ip.length > 0 ? (
                                                    environment.running_ip.split(":")[0]
                                                ) : (
                                                    <div>Not running</div>
                                                )
                                            ) : (
                                                "N/A"
                                            )
                                        }
                                        style={{
                                            minWidth: 80,
                                            maxWidth: 80,
                                            overflow: "hidden",
                                        }}
                                    />
                                    {/* Command */}
                                    <ListItemText
                                        style={{ minWidth: 66, maxWidth: 66 }}
                                        primary={
                                            <Tooltip
                                                title="Copy Orborus command"
                                                style={{}}
                                                aria-label="Copy orborus command"
                                            >
                                                <IconButton
                                                    style={{}}
                                                    disabled={environment.Type === "cloud"}
                                                    onClick={() => {
                                                        if (environment.Type === "cloud") {
                                                            toast("No Orborus necessary for environment cloud. Create and use a different environment to run executions on-premises.");
                                                            return;
                                                        }

                                                        if (!props.userdata.active_org) {
                                                            toast("No active organization yet. Are you logged in?");
                                                            return;
                                                        }

                                                        const elementName = "copy_element_shuffle";
                                                        const auth = environment.auth === "" ? 'cb5st3d3Z!3X3zaJ*Pc' : environment.auth;
                                                        const newUrl = globalUrl === "https://shuffler.io" ? "https://shuffle-backend-stbuwivzoq-nw.a.run.app" : globalUrl;

                                                        const commandData = `docker run --restart=always --volume "/var/run/docker.sock:/var/run/docker.sock" -e ENVIRONMENT_NAME="${environment.Name}" -e 'AUTH=${auth}' -e ORG="${props.userdata.active_org.id}" -e DOCKER_API_VERSION=1.40 -e BASE_URL="${newUrl}" --name="shuffle-orborus" -d ghcr.io/shuffle/shuffle-orborus:latest`;
                                                        const copyText = document.getElementById(elementName);
                                                        if (copyText) {
                                                            navigator.clipboard.writeText(commandData);
                                                            copyText.select();
                                                            copyText.setSelectionRange(0, 99999); /* For mobile devices */
                                                            document.execCommand("copy");
                                                            toast("Orborus command copied to clipboard");
                                                        }
                                                    }}
                                                >
                                                    <FileCopyIcon
                                                        disabled={environment.Type === "cloud"}
                                                        style={{ color: environment.Type === "cloud" ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0.8)" }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    />
                                    {/* Type */}
                                    <ListItemText
                                        primary={environment.Type}
                                        style={{ minWidth: 100, maxWidth: 100, textAlign: "center" }}
                                    />
                                    {/* Queue */}
                                    <ListItemText
                                        style={{ minWidth: 30, maxWidth: 30, marginLeft: 22 }}
                                        primary={queueSizeText(environment.queue)}
                                    />
                                    {/* Default */}
                                    <ListItemText style={{ minWidth: 110, maxWidth: 110 }}>
                                        {environment.default ? null : (
                                            <Button
                                                style={{ backgroundColor: 'rgba(255, 132, 68, 0.2)', color: "#FF8444", borderRadius: 200, boxShadow: "none", textTransform: "capitalize", fontSize: 16 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setDefaultEnvironment(environment)}
                                            >
                                                Set Default
                                            </Button>
                                        )}
                                    </ListItemText>
                                    {/* Actions */}
                                    <ListItemText
                                        style={{ minWidth: 200, maxWidth: 200, overflow: "hidden", marginLeft: 10 }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <ButtonGroup style={{ borderRadius: "5px 5px 5px 5px" }}>
                                                <Button
                                                    variant={environment.archived ? "contained" : "outlined"}
                                                    style={{}}
                                                    onClick={() => deleteEnvironment(environment)}
                                                    color="primary"
                                                >
                                                    {environment.archived ? "Activate" : "Disable"}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    style={{}}
                                                    onClick={() => {
                                                        console.log("Should clear executions for: ", environment);

                                                        if (isCloud && environment.Name.toLowerCase() === "cloud") {
                                                            rerunCloudWorkflows(environment);
                                                        } else {
                                                            abortEnvironmentWorkflows(environment);
                                                        }
                                                    }}
                                                    color="primary"
                                                >
                                                    {isCloud && environment.Name.toLowerCase() === "cloud" ? "Rerun" : "Clear"}
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </ListItemText>
                                    {/* Last Edited */}
                                    <ListItemText
                                        style={{ minWidth: 170, maxWidth: 170, overflow: "hidden" }}
                                        primary={
                                            environment.edited ? new Date(environment.edited * 1000).toISOString() : 0
                                        }
                                    />
                                </ListItem>
                                {showCPUAlert && (
                                    <ListItem key={`${index}_cpu`} style={{ backgroundColor: bgColor }}>
                                        <div style={{ border: "1px solid #f85a3e", borderRadius: theme.palette?.borderRadius, marginTop: 10, marginBottom: 10, padding: 15, textAlign: "center", height: 70, textAlign: "left", backgroundColor: theme.palette.surfaceColor, display: "flex" }}>
                                            <div style={{ flex: 2, overflow: "hidden" }}>
                                                <Typography variant="body1">
                                                    90% CPU the server(s) hosting the Shuffle App Runner (Orborus) was found.
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Need help with High Availability and Scale?{" "}
                                                    <a href="/docs/configuration#scale" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#f85a3e" }}>
                                                        Read documentation
                                                    </a>{" "}
                                                    and{" "}
                                                    <a href="https://shuffler.io/contact" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#f85a3e" }}>
                                                        Get in touch
                                                    </a>
                                                    .
                                                </Typography>
                                            </div>
                                            <div style={{ flex: 1, display: "flex", marginLeft: 30 }}>
                                                <Button
                                                    style={{ borderRadius: 25, width: 200, height: 50, marginTop: 8 }}
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => {
                                                        // dismiss -> get envs
                                                        // changeRecommendation(userdata.priorities[foundIndex], "dismiss");
                                                    }}
                                                >
                                                    Dismiss
                                                </Button>
                                            </div>
                                        </div>
                                    </ListItem>
                                )}
                            </span>
                        );
                    })}
            </List>
            {/* <EnvironmentStats /> */}
        </div>
    );
};

export default EnvironmentTab;
