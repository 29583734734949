import React, { useEffect, useState } from 'react';
import theme from "../theme.jsx";
import {
    FormControl,
    Card,
    Tooltip,
    Typography,
    Switch,
    Divider,
    TextField,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
  } from "@mui/material";
  
  import {
    Edit as EditIcon,
    Polyline as PolylineIcon,
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon,
    Apps as AppsIcon,
    Business as BusinessIcon,
  } from "@mui/icons-material";

import { toast } from 'react-toastify';

const TenantsTab = (props) => {
    const {
        globalUrl,
        isCloud,
        userdata,
        selectedOrganization,
        setSelectedOrganization
    } = props;
    const imageStyle = { width: 50, height: 50 };
    // const [selectedOrganization, setSelectedOrganization] = React.useState({});
    const [subOrgs, setSubOrgs] = useState([]);
    const [cloudSyncApikey, setCloudSyncApikey] = React.useState("");
    const [orgName, setOrgName] = React.useState("");
    const [orgSyncResponse, setOrgSyncResponse] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    // const [loginInfo, setLoginInfo] = React.useState("");
    const [cloudSyncModalOpen, setCloudSyncModalOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [parentOrg, setParentOrg] = React.useState(null);
    const [, forceUpdate] = React.useState();
    console.log("selectedOrganization", selectedOrganization)
    const itemColor = "black";
    var syncList = [
        {
            primary: "Workflows",
            secondary: "",
            active: true,
            icon: <PolylineIcon style={{ color: itemColor }} />,
        },
        {
            primary: "Apps",
            secondary: "",
            active: true,
            icon: <AppsIcon style={{ color: itemColor }} />,
        },
        {
            primary: "Organization",
            secondary: "",
            active: true,
            icon: <BusinessIcon style={{ color: itemColor }} />,
        },
    ];

    useEffect(() => {
        if (userdata.orgs !== undefined && userdata.orgs !== null && userdata.orgs.length > 0) {
            handleGetSubOrgs(userdata.active_org.id);
        }
        else console.log("error in user data")
    }, [userdata]);

    const handleGetSubOrgs = (orgId) => {

        if (orgId.length === 0) {
            toast("Organization ID not defined. Please contact us on https://shuffler.io if this persists logout.");
            return;
        }

        fetch(`${globalUrl}/api/v1/orgs/${orgId}/suborgs`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch sub organizations');
                }
                return response.json();
            })
            .then((responseJson) => {
                if (responseJson.success === false) {
                    //toast("Failed getting your org. If this persists, please contact support.");
                } else {
                    const { subOrgs, parentOrg } = responseJson;
                    setSubOrgs(subOrgs);
                    setParentOrg(parentOrg);
                }
            })
            .catch((error) => {
                console.log("Error getting sub orgs: ", error);
                //toast("Error getting sub organizations");
            });
    };

    const GridItem = (props) => {
        const [expanded, setExpanded] = React.useState(false);
        const [showEdit, setShowEdit] = React.useState(false);
        const [newValue, setNewValue] = React.useState(-100);

        const primary = props.data.primary;
        const secondary = props.data.secondary;
        const primaryIcon = props.data.icon;
        const secondaryIcon = props.data.active ? (
            <CheckCircleIcon style={{ color: "green" }} />
        ) : (
            <CloseIcon style={{ color: "red" }} />
        );

        const submitFeatureEdit = (sync_features) => {
            if (!userdata.support) {
                console.log(
                    "User does not have support access and can't edit features",
                );
                return;
            }

            sync_features.editing = true;
            const data = {
                org_id: selectedOrganization.id,
                sync_features: sync_features,
            };

            const url = globalUrl + `/api/v1/orgs/${selectedOrganization.id}`;
            fetch(url, {
                mode: "cors",
                method: "POST",
                body: JSON.stringify(data),
                credentials: "include",
                crossDomain: true,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            })
                .then((response) =>
                    response.json().then((responseJson) => {
                        if (responseJson["success"] === false) {
                            toast("Failed updating org: ", responseJson.reason);
                        } else {
                            toast("Successfully edited org!");
                        }
                    }),
                )
                .catch((error) => {
                    toast("Err: " + error.toString());
                });
        };

        const enableFeature = () => {
            console.log("Enabling " + primary);

            console.log(selectedOrganization.sync_features);
            // Check if primary is in sync_features
            var tmpprimary = primary.replaceAll(" ", "_");
            if (!(tmpprimary in selectedOrganization.sync_features)) {
                console.log("Primary not in sync_features: " + tmpprimary);
                return;
            }

            if (props.data.active) {
                selectedOrganization.sync_features[tmpprimary].active = false;
            } else {
                selectedOrganization.sync_features[tmpprimary].active = true;
            }

            // setSelectedOrganization(selectedOrganization);
            forceUpdate(Math.random());
            submitFeatureEdit(selectedOrganization.sync_features);
        };

        const submitEdit = (e) => {
            e.preventDefault();
            e.stopPropagation();

            // Check if primary is in sync_features
            var tmpprimary = primary.replaceAll(" ", "_");
            if (!(tmpprimary in selectedOrganization.sync_features)) {
                console.log("Primary not in sync_features: " + tmpprimary);
                return;
            }

            // Make it into a number
            var tmp = parseInt(newValue);
            if (isNaN(tmp)) {
                console.log("Not a number: " + newValue);
                return;
            }

            selectedOrganization.sync_features[tmpprimary].limit = tmp;

            // setSelectedOrganization(selectedOrganization);
            forceUpdate(Math.random());
            submitFeatureEdit(selectedOrganization.sync_features);
        };

        return (
            <Grid item xs={4}>
                <Card
                    style={{
                        margin: 4,
                        backgroundColor: theme.palette.platformColor,
                        borderRadius: theme.palette?.borderRadius,
                        border: "1px solid rgba(255,255,255,0.3)",
                        color: "white",
                        minHeight: expanded ? 250 : "inherit",
                        maxHeight: expanded ? 300 : "inherit",
                    }}
                >
                    <ListItem
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>{primaryIcon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            style={{ textTransform: "capitalize" }}
                            primary={props.data.newname ? props.data.newname : primary}
                        />
                        {isCloud && userdata.support === true ? (
                            <Tooltip title="Edit features (support users only)">
                                <EditIcon
                                    color="secondary"
                                    style={{ marginRight: 10, cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (showEdit) {
                                            setShowEdit(false);
                                            return;
                                        }

                                        console.log("Edit");

                                        setShowEdit(true);
                                    }}
                                />
                            </Tooltip>
                        ) : null}
                        <Tooltip
                            title={props.data.active ? "Disable feature" : "Enable feature"}
                        >
                            <span
                                style={{ cursor: "pointer", marginTop: 5 }}
                                onClick={(e) => {
                                    if (!isCloud || userdata.support !== true) {
                                        return;
                                    }

                                    e.preventDefault();
                                    e.stopPropagation();

                                    enableFeature();
                                }}
                            >
                                {secondaryIcon}
                            </span>
                        </Tooltip>
                    </ListItem>
                    {expanded ? (
                        <div style={{ padding: 15 }}>
                            <Typography>
                                <b>Usage:&nbsp;</b>
                                {props.data.limit === 0 ? (
                                    "Unlimited"
                                ) : (
                                    <span>
                                        {props.data.usage} /{" "}
                                        {props.data.limit === "" ? "Unlimited" : props.data.limit}
                                    </span>
                                )}
                            </Typography>
                            {/*<Typography>
                    Data sharing: {props.data.data_collection}
                  </Typography>*/}
                            <Typography
                                style={{
                                    maxHeight: 150,
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                }}
                            >
                                <b>Description:</b> {secondary}
                            </Typography>
                        </div>
                    ) : null}

                    {showEdit ? (
                        <FormControl
                            fullWidth
                            onSubmit={(e) => {
                                console.log("Submit");
                                submitEdit(e);
                            }}
                        >
                            <span style={{ display: "flex" }}>
                                <TextField
                                    style={{ flex: 3 }}
                                    color="primary"
                                    label={"Edit value"}
                                    defaultValue={props.data.limit}
                                    style={{}}
                                    onChange={(event) => {
                                        setNewValue(event.target.value);
                                    }}
                                />
                                <Button
                                    style={{ flex: 1 }}
                                    variant="contained"
                                    disabled={newValue < -1}
                                    onClick={(e) => {
                                        console.log("Submit 2");
                                        submitEdit(e);
                                    }}
                                >
                                    Submit
                                </Button>
                            </span>
                        </FormControl>
                    ) : null}
                </Card>
            </Grid>
        );
    };

    const enableCloudSync = (apikey, organization, disableSync) => {
        setOrgSyncResponse("");

        const data = {
            apikey: apikey,
            organization: organization,
            disable: disableSync,
        };

        const url = globalUrl + "/api/v1/cloud/setup";
        fetch(url, {
            mode: "cors",
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
            crossDomain: true,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    console.log("Cloud sync success?");
                } else {
                    console.log("Cloud sync fail?");
                }

                return response.json();
                //setTimeout(() => {
                //}, 1000)
            })
            .then((responseJson) => {
                console.log("RESP: ", responseJson);
                if (
                    responseJson.success === false &&
                    responseJson.reason !== undefined
                ) {
                    setOrgSyncResponse(responseJson.reason);
                    toast("Failed to handle sync: " + responseJson.reason);
                } else if (!responseJson.success) {
                    toast("Failed to handle sync.");
                } else {
                    //   getOrgs();
                    if (disableSync) {
                        toast("Successfully disabled sync!");
                        setOrgSyncResponse("Successfully disabled syncronization");
                    } else {
                        toast("Cloud Syncronization successfully set up!");
                        setOrgSyncResponse(
                            "Successfully started syncronization. Cloud features you now have access to can be seen below.",
                        );
                    }

                    selectedOrganization.cloud_sync = !selectedOrganization.cloud_sync;
                    // setSelectedOrganization(selectedOrganization);
                    setCloudSyncApikey("");

                    //   handleGetOrg(userdata.active_org.id);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast("Err: " + error.toString());
            });
    };

    const createSubOrg = (currentOrgId, name) => {
        const data = { name: name, org_id: currentOrgId };
        console.log(data);
        const url = globalUrl + `/api/v1/orgs/${currentOrgId}/create_sub_org`;

        fetch(url, {
            mode: "cors",
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
            crossDomain: true,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then((response) =>
                response.json().then((responseJson) => {
                    if (responseJson["success"] === false) {
                        if (responseJson.reason !== undefined) {
                            toast(responseJson.reason);
                        } else {
                            toast("Failed creating suborg. Please try again");
                        }
                    } else {
                        toast("Successfully created suborg. Reloading in 3 seconds!");
                        // setSelectedUserModalOpen(false);

                        setTimeout(() => {
                            window.location.reload();
                        }, 2500);
                    }

                    setOrgName("");
                    setModalOpen(false);
                }),
            )
            .catch((error) => {
                toast("Err: " + error.toString());
            });
    };

    const handleClickChangeOrg = (orgId) => {
        // Don't really care about the logout
        //name: org.name,
        //orgId = "asd"
        const data = {
            org_id: orgId,
        };

        localStorage.setItem("globalUrl", "");
        localStorage.setItem("getting_started_sidebar", "open");

        fetch(`${globalUrl}/api/v1/orgs/${orgId}/change`, {
            mode: "cors",
            credentials: "include",
            crossDomain: true,
            method: "POST",
            body: JSON.stringify(data),
            withCredentials: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then(function (response) {
                if (response.status !== 200) {
                    console.log("Error in response");
                } else {
					localStorage.setItem("apps", [])
				}

                return response.json();
            })
            .then(function (responseJson) {
                if (responseJson.success === true) {
                    if (
                        responseJson.region_url !== undefined &&
                        responseJson.region_url !== null &&
                        responseJson.region_url.length > 0
                    ) {
                        localStorage.setItem("globalUrl", responseJson.region_url);
                        //globalUrl = responseJson.region_url
                    }

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    toast("Successfully changed active organization - refreshing!");
                } else {
                    toast("Failed changing org: " + responseJson.reason);
                }
            })
            .catch((error) => {
                console.log("error changing: ", error);
                //removeCookie("session_token", {path: "/"})
            });
    };

    const modalView = (
        <Dialog
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
            }}
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.surfaceColor,
                    color: "white",
                    minWidth: "800px",
                    minHeight: "320px",
                },
            }}
        >
            <DialogTitle>
                <span style={{ color: "white" }}>Add Sub-Organization</span>
            </DialogTitle>
            <DialogContent>
                <div>
                    Name
                    <TextField
                        color="primary"
                        style={{ backgroundColor: theme.palette.inputColor }}
                        autoFocus
                        InputProps={{
                            style: {
                                height: "50px",
                                color: "white",
                                fontSize: "1em",
                            },
                        }}
                        required
                        fullWidth={true}
                        placeholder={`${selectedOrganization.name} Copycat Inc.`}
                        id="orgname"
                        margin="normal"
                        variant="outlined"
                        onChange={(event) => {
                            setOrgName(event.target.value);
                        }}
                    />
                </div>
                {/* {loginInfo} */}
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ borderRadius: "0px" }}
                    onClick={() => setModalOpen(false)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    style={{ borderRadius: "0px" }}
                    onClick={() => {
                        createSubOrg(selectedOrganization.id, orgName);
                    }}
                    color="primary"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );

    const cloudSyncModal = (
        <Dialog
            open={cloudSyncModalOpen}
            onClose={() => {
                setCloudSyncModalOpen(false);
            }}
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.surfaceColor,
                    color: "white",
                    minWidth: "800px",
                    minHeight: "320px",
                },
            }}
        >
            <DialogTitle>
                <span style={{ color: "white" }}>Enable cloud features</span>
            </DialogTitle>
            <DialogContent color="textSecondary">
                What does{" "}
                <a
                    href="/docs/organizations#cloud_sync"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#f85a3e" }}
                >
                    cloud sync
                </a>{" "}
                do?
                <div style={{ display: "flex", marginBottom: 20 }}>
                    <TextField
                        color="primary"
                        style={{
                            backgroundColor: theme.palette.inputColor,
                            marginRight: 10,
                        }}
                        InputProps={{
                            style: {
                                height: "50px",
                                color: "white",
                                fontSize: "1em",
                            },
                        }}
                        required
                        fullWidth={true}
                        disabled={selectedOrganization.cloud_sync}
                        autoComplete="cloud apikey"
                        id="apikey_field"
                        margin="normal"
                        placeholder="Cloud Apikey"
                        variant="outlined"
                        onChange={(event) => {
                            setCloudSyncApikey(event.target.value);
                        }}
                    />
                    <Button
                        disabled={
                            (!selectedOrganization.cloud_sync &&
                                cloudSyncApikey.length === 0) ||
                            loading
                        }
                        style={{ marginLeft: 15, height: 50, borderRadius: "0px" }}
                        onClick={() => {
                            setLoading(true);
                            enableCloudSync(
                                cloudSyncApikey,
                                selectedOrganization,
                                selectedOrganization.cloud_sync,
                            );
                        }}
                        color="primary"
                        variant={
                            selectedOrganization.cloud_sync === true
                                ? "outlined"
                                : "contained"
                        }
                    >
                        {selectedOrganization.cloud_sync ? "Stop sync" : "Start sync"}
                    </Button>
                </div>
                {orgSyncResponse.length > 0 ? (
                    <Typography style={{ marginTop: 5, marginBottom: 10 }}>
                        Error: {orgSyncResponse}
                    </Typography>
                ) : null}
                <Grid container style={{ width: "100%", marginBottom: 15 }}>
                    {syncList.map((data, index) => {
                        return <GridItem key={index} data={data} />;
                    })}
                </Grid>
                * New triggers (userinput, hotmail realtime)
                <div />
                * Execute in the cloud rather than onprem
                <div />
                * Apps can be built in the cloud
                <div />
                * Easily share apps and workflows
                <div />* Access to powerful cloud search
            </DialogContent>
        </Dialog>
    );


    const textColor = "#9E9E9E !important";

    return (
        <div style={{ width: 1030, padding: 27, height: "auto", backgroundColor: '#212121', borderRadius: '16px', }}>
            {modalView}
            {cloudSyncModal}
            <div style={{ marginBottom: 20 }}>
                <h2 style={{ marginBottom: 8, marginTop: 0, color: "#ffffff" }}>Organizations</h2>
                <span style={{ color: textColor }}>
                    Control sub organizations (tenants)! {" "}
                    {isCloud
                        ? "You can only make a sub organization if you are a customer of shuffle or running a POC of the platform. Please contact support@shuffler.io to try it out."
                        : ''}
                    <a
                        href="/docs/organizations"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#FF8444" }}
                    >
                        Learn more
                    </a>
                </span>
            </div>

            <Button
                style={{ backgroundColor: 'rgba(255, 132, 68, 0.2)', color: "#FF8444", borderRadius: 200, width: 212, height: 40 }}
                variant="contained"
                color="primary"
                // disabled={userdata.admin !== 'true'}
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                Add suborganization
            </Button>

            {parentOrg && (
                <div>
                    <Divider
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            backgroundColor: theme.palette.inputColor,
                        }}
                    />

                    <div
                        style={{
                            textAlign: 'left',
                            width: '100%',
                            padding: '10px',
                            marginTop: 20,
                        }}
                    >
                        <h3
                            style={{
                                margin: 0,
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                letterSpacing: '1px',
                            }}
                        >
                            Your Parent Organization
                        </h3>
                    </div>

                    {/* <Divider
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            backgroundColor: theme.palette.inputColor,
                        }}
                    /> */}

                    <List style={{ borderRadius: 8, border: "1px solid #494949", marginTop: 14 }}>
                        <ListItem style={{ width: "100%", borderBottom: "1px solid #494949" }}>
                            <ListItemText primary="Logo" style={{ minWidth: 100, maxWidth: 100 }} />
                            <ListItemText primary="Name" style={{ minWidth: 350, maxWidth: 350 }} />
                            <ListItemText primary="id" style={{ minWidth: 400, maxWidth: 400 }} />
                        </ListItem>
                        <ListItem style={{ backgroundColor: '#1A1A1A' }}>
                            <ListItemText primary={<img alt={parentOrg.name} src={parentOrg.image || theme.palette.defaultImage} style={imageStyle} />} style={{ minWidth: 100, maxWidth: 100 }} />
                            <ListItemText primary={parentOrg.name} style={{ minWidth: 300, maxWidth: 300 }} />
                            <ListItemText primary={parentOrg.id} style={{ minWidth: 300, maxWidth: 300 }} />

                            <Button
                                variant="contained"
                                style={{ backgroundColor: 'rgba(255, 132, 68, 0.2)', boxShadow:"none", textTransform:"capitalize", fontSize:16, color: "#FF8444", borderRadius: 10, width: 220, }}
                                color="primary"
                                disabled={parentOrg.id === selectedOrganization.id}
                                onClick={() => {
                                    handleClickChangeOrg(parentOrg.id);
                                }}
                            >
                                Switch to Parent
                            </Button>
                        </ListItem>
                    </List>
                </div>
            )}

            {subOrgs.length > 0 && (
                <div>
                    <Divider
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            backgroundColor: theme.palette.inputColor,
                        }}
                    />

                    <div
                        style={{
                            textAlign: 'left',
                            width: '100%',
                            padding: '10px',
                            marginTop: 20,
                        }}
                    >
                        <h3
                            style={{
                                margin: 0,
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                letterSpacing: '1px',
                            }}
                        >
                            Sub Organizations of the Current Organization ({subOrgs.length})
                        </h3>
                    </div>

                    {/* <Divider
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            backgroundColor: theme.palette.inputColor,
                        }}
                    /> */}

                    <List style={{ borderRadius: 8, color: "#ffffff", border: "1px solid #494949", marginTop: 14 }}>
                        <ListItem style={{ width: "100%", borderBottom: "1px solid #494949" }}>
                            <ListItemText primary="Logo" style={{ minWidth: 100, maxWidth: 100 }} />
                            <ListItemText primary="Name" style={{ minWidth: 350, maxWidth: 350 }} />
                            <ListItemText primary="id" style={{ minWidth: 400, maxWidth: 400 }} />
                        </ListItem>
                        {subOrgs.map((data, index) => (
                            <ListItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#1A1A1A' : '#212121' }}>
                                <ListItemText primary={<img alt={data.name} src={data.image || theme.palette.defaultImage} style={imageStyle} />} style={{ minWidth: 100, maxWidth: 100 }} />
                                <ListItemText primary={data.name} style={{ minWidth: 300, maxWidth: 350 }} />
                                <ListItemText primary={data.id} style={{ minWidth: 300, maxWidth: 400 }} />

                                <Button
                                    color="primary"
                                    disabled={data.id === selectedOrganization.id}
                                    onClick={() => {
                                        handleClickChangeOrg(data.id);
                                    }}
                                    style={{ backgroundColor: 'rgba(255, 132, 68, 0.2)', boxShadow:"none", textTransform:"capitalize", fontSize:16, color: "#FF8444", borderRadius: 10, width: 220, }}
                                >
                                    Change Active Org
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}

            <Divider
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                    backgroundColor: theme.palette.inputColor,
                }}
            />

            <div style={{ textAlign: 'left', width: '100%', padding: '10px', marginTop: 20 }}>
                <h3
                    style={{
                        margin: 0,
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        letterSpacing: '1px',
                    }}
                >
                    All Tenants
                </h3>
            </div>

            {/* <Divider
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                    backgroundColor: theme.palette.inputColor,
                }}
            /> */}

            <List style={{ borderRadius: 8, color: "#ffffff", border: "1px solid #494949", marginTop: 14 }}>
                <ListItem style={{ width: "100%", borderBottom: "1px solid #494949" }}>
                    <ListItemText primary="Logo" style={{ minWidth: 80, maxWidth: 80 }} />
                    <ListItemText primary="Name" style={{ minWidth: 200, maxWidth: 200 }} />
                    <ListItemText primary="Your role" style={{ minWidth: 100, maxWidth: 100 }} />
                    <ListItemText primary="id" style={{ minWidth: 400, maxWidth: 400 }} />
                    <ListItemText primary="Selected" style={{ minWidth: 100, maxWidth: 100 }} />
                    <ListItemText primary="Cloud Sync" style={{ minWidth: 100, maxWidth: 100 }} />
                </ListItem>
                {userdata.orgs !== undefined &&
                    userdata.orgs !== null &&
                    userdata.orgs.length > 0 &&
                    userdata.orgs.map((data, index) => (
                        <ListItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#1A1A1A' : '#212121' }}>
                            <ListItemText primary={<img alt={data.name} src={data.image || theme.palette.defaultImage} style={imageStyle} />} style={{ minWidth: 80, maxWidth: 80 }} />
                            <ListItemText primary={data.name} style={{ minWidth: 200, maxWidth: 200 }} />
                            <ListItemText primary={data.role} style={{ minWidth: 100, maxWidth: 100 }} />
                            <ListItemText primary={data.id} style={{ minWidth: 400, maxWidth: 400 }} />
                            <ListItemText primary={props.userdata.active_org.id === data.id ? 'True' : 'False'} style={{color: props.userdata.active_org.id === data.id ? '#02CB70' : '#F53434', minWidth: 100, maxWidth: 100 }} />
                            <ListItemText
                                primary={
                                    <Switch
                                        checked={data.cloud_sync}
                                        onChange={() => {
                                            setCloudSyncModalOpen(true);
                                            // setSelectedOrganization(data);
                                            console.log('INVERT CLOUD SYNC');
                                        }}
                                    />
                                }
                                style={{ minWidth: 100, maxWidth: 100 }}
                            />
                        </ListItem>
                    ))}
            </List>
        </div>
    );
};

export default TenantsTab;
