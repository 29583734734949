import React, { useState, useEffect } from 'react';

import { isMobile } from "react-device-detect";
import { Grid, Container } from '@mui/material'
import { makeStyles } from "@mui/styles";
import theme from '../theme.jsx';
import DoneIcon from '@mui/icons-material/Done';
import {
	Typography,
	Divider,
	Paper,
	Button,
	TableHead,
	TableRow,
	TableContainer,
	TableCell,
	TableBody,
	Table,
} from '@mui/material';
import { tableCellClasses } from "@mui/material/TableCell";

import Carousel from "../components/PartnerCarousel.jsx"
import Contact from "./ContactPartner.jsx";

const useStyles = makeStyles({
	table: {
		minWidth: 550,
	}
});


const title = {
	//margin : "30px",
	border: "3px ridge #de5f1f",
}

const Partnerpage = (props) => {
	const classes = useStyles();
	const { globalUrl, surfaceColor, newColor, mini, inputColor } = props;

	const buttonBackground = "linear-gradient(to right, #f86a3e, #f34079)"
	const buttonStyle = { borderRadius: 25, height: 40, width: 200, color: "white", margin: isMobile ? "15px auto 15px auto" : 20, fontSize: 14, backgroundImage: buttonBackground, textTransform: "none" }
	const textColor = "rgba(255, 255, 255, 0.75)"

	const createData = (type, Authorized, SilverPartner, GoldPartner) => {
		return {
			type,
			Authorized,
			SilverPartner,
			GoldPartner,
		}
	}

	const activeIcon = <DoneIcon style={{ color: "green" }} />
	const rows = [
		createData("Authorized to resell Shuffle services", activeIcon, activeIcon, activeIcon),
		createData("Access to deal registration", activeIcon, activeIcon, activeIcon),
		createData("List price for initial tiers", activeIcon, activeIcon, activeIcon),
		createData("Listed as Partner on our websites", " ", activeIcon, activeIcon),
		createData("Access to renewals", " ", activeIcon, activeIcon),
		createData("Price protection policy", " ", activeIcon, activeIcon),
		createData("Pre-sales assistance", " ", activeIcon, activeIcon),
		createData("Support portal access", " ", activeIcon, activeIcon),
		createData("Access to a demo environment", " ", activeIcon, activeIcon),
		createData("Centralized partner console", " ", activeIcon, activeIcon),
		createData("Console walkthrough", " ", activeIcon, activeIcon),
		createData("Can run demos", " ", activeIcon, activeIcon),
		createData("Press release to announce partnership", " ", " ", activeIcon),
		createData("Co-Marketing campaigns", " ", " ", activeIcon),
		createData("Discounts", "10%", "20%", "30%"),
	];

	return (
		<div maxWidth="xl" style={{ width: isMobile? "100%":"550px", alignItems: "center", minWidth: isMobile?"100%":"768px", margin: "auto", marginTop: 100, }}>
			<center>
				{/*<img src="images/Shuffle_logo.png" alt="Shuffle logo top" style={{height: 50, width: 50, }} />*/}
				<h1>Become a Shuffle partner</h1>
			</center>
			<center>
				<p>
					Shuffle offers our partners a way to grow together. We are the fastest growing automation platform for security professionals, and work with our partners to deliver immense value to the global market.
				</p>
			</center>
			<center>
				<Button
					variant={isMobile ? "contained" : "outlined"}
					color={isMobile ? "primary" : "secondary"}
					style={buttonStyle}
					onClick={() => {
						window.location.href = "#contactscroll";

						const founditem = document.getElementById("contactscroll");
						if (founditem) {
							founditem.scrollIntoView({ behavior: "smooth" });
						}
					}}
				>
					Apply now
				</Button>
			</center>

			{/*
            <Typography variant="h6" style={{textAlign: "center", marginTop: 100, marginBottom: 15, }}>
							Partnership opportunities
						</Typography>
						*/}
			<div style={{ width: isMobile?"100%":"100%", minWidth: isMobile?"100%":"768px", alignItems: "center", display: "flex", margintop: 100, }}>
				<div style={{ flex: 1, padding: isMobile?30:75, }}>
					<img src="images/partner/reseller.png" alt="Shuffle logo with reseller" style={{ width: "100%", }} />
					<Typography variant="body1" style={{ textAlign: "center", marginTop: 10, }}>
						Become a reseller
					</Typography>
				</div>
				<div style={{ flex: 1, padding: isMobile?30:75, borderLeft: "1px solid rgba(255,255,255,0.2)", }}>
					<img src="images/partner/growth.png" alt="Grow together with Shuffle colors" style={{ marginTop: 50, width: isMobile?"100%":190, }} />
					<Typography variant="body1" style={{ textAlign: "center", marginTop: 10, }}>
						Scale together
					</Typography>
				</div>
			</div>


			<Grid container spacing={2} style={{ margin: isMobile?"":"50px auto 10px auto", color: "white", backgroundColor: theme.palette.surfaceColor, padding: isMobile?25:50, }}>
				<Grid item md={5}>
					<img src="images/partner/support.png" alt="Full support for your business logo with shuffle logo in the middle" style={{ marginRight: 100, marginTop: 20, }} />
				</Grid>
				<Grid item md={6}>
					<Typography variant="h6">Grow your business with our full support</Typography>
					<ul style={{ color: "#b1b1b2" }}>
						<li>Sales support & training</li>
						<li>Technical support & training</li>
						<li>Marketing material</li>
						<li>Access to a testing/demo cloud environment</li>
					</ul>

					<Typography variant="h6" style={{ color: "white" }}>Increase margins and revenue</Typography>
					<Typography variant="body1" style={{ color: "#b1b1b2" }}>
						Our accessible platform with affordable pricing makes for a tool everyone in the industry needs. Automation for everyone.
					</Typography>
				</Grid>
			</Grid>

			<Typography variant="h6" style={{ textAlign: "center", marginTop: 100, marginBottom: 25, }}>
				Explore our partnership plans
			</Typography>
			<TableContainer
				component={Paper}
				style={{
					backgroundColor: "inherit",
				}}
			>
				<Table
					size="medium"
					aria-label="a dense table"
					sx={{
						[`& .${tableCellClasses.root}`]: {
							borderBottom: "none"
						}
					}}

				>
					<TableHead style={{ height: 50, }}>
						<TableRow style={{ backgroundColor: theme.palette.surfaceColor }}>
							<TableCell component="th" scope="row" style={{ borderTop: null, borderBottom: null, borderRight: "1px solid rgba(255,255,255,0.75)", }}>
								<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
									Feature
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
									Authorized Partner
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
									Silver Partner
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
									Gold Partner
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow key={row.type} style={{ backgroundColor: index % 2 === 0 ? "inherit" : theme.palette.surfaceColor, borderTop: null, borderBottom: null }}>
								<TableCell component="th" scope="row" style={{ borderTop: null, borderBottom: null, borderRight: "1px solid rgba(255,255,255,0.75)", }}>
									<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
										{row.type}
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
										{row.Authorized}
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
										{row.SilverPartner}
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography variant="body1" color="textPrimary" style={{ color: textColor }}>
										{row.GoldPartner}
									</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Divider />
			<Grid style={{ marginTop: 15, }}>
				<Carousel />
			</Grid>
			<Grid id="contactscroll">
				<Contact
					globalUrl={globalUrl}
					{...props}
				/>
			</Grid>
		</div >
	);
};

export default Partnerpage; 
